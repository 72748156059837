import { PlusOutlined, MinusOutlined, SaveOutlined } from '@ant-design/icons';
import {
	Row,
	Col,
	Select,
	Button,
	Table,
	Input,
	notification,
	Descriptions,
	InputNumber,
	Cascader,
	Skeleton,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import descMovements from '../../components/dictionary/descMovs.json';
import {
	getMovement,
	cancelMovement,
	createMovement,
} from '../../services/logisticMovementService';
import { numberFormat } from '../../utils/numberFormat';
import { getAllPlants } from '../../services/PlantsService';
import MovementsProductsModal from './modal';
import { getTemplate, updateTemplate } from '../../services/templateService';
import config from '../../config.json';

const { dateTimeFormat } = config;

export default function Movements(props) {
	const { Option } = Select;

	const plantsCascade = p => ({
		value: p.id,
		label: p.name,
		children: p.storageLocations.map(s => ({ value: s.id, label: s.name })),
	});
	const [loading, setLoading] = useState(false);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedMovement, setSelectedMovement] = useState(null);
	const [showProductsModal, setShowProductsModal] = useState(false);
	const [movement, setMovement] = useState(null);
	const [items, setItems] = useState([]);
	const [tableLoading, setTableLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [plants, setPlants] = useState([]);
	const [template, setTemplate] = useState(null);
	const [tmpLoading, setTmpLoading] = useState(null);
	const searchParams = useSearchParams();
	const urlTemplate = searchParams[0].get('template');

	useEffect(() => {
		async function process() {
			setLoading(true);
			const response = await getAllPlants();
			setPlants(response.data);
			setLoading(false);
		}

		async function template() {
			if (urlTemplate) {
				setTmpLoading(true);
				setTableLoading(true);
				const { data } = await getTemplate(urlTemplate);
				data.data = JSON.parse(data.data);
				setSelectedType(parseInt(data.data.MovementTypeId[0], 10));
				setSelectedMovement(data.data.MovementTypeId);
				setItems(
					data.data.Details.map((n, i) => ({
						position: i + 1,
						quantity: n.Quantity,
						text: n.Text,
						baseUnit: n.UnitOfMeasureId,
						categoryId: n.CategoryId,
						category: n.Category,
						productId: n.ProductId,
						name: n.Name,
						description: n.Description,
						storageLocation: [n.PlantId, n.StorageLocationId],
						plantId: n.PlantId,
						storageLocationId: n.StorageLocationId,
						costCenterId: n.CostCenterId,
					}))
				);
				setTemplate(data);
				setTmpLoading(false);
				setTableLoading(false);
			}
		}

		process();
		template();
	}, []);

	const handleTypeChange = value => {
		setSelectedType(value);

		// If movement type 1 (Entrada), then selected movement "110" (Entrada por compra)
		setSelectedMovement(value === 1 ? '110' : null);

		setItems([]);
		setMovement(null);
	};

	const handleTextChange = (position, text) => {
		const tmp = items.slice();
		tmp[position - 1].text = text;
		setItems(tmp);
	};

	const handleBatchChange = (position, batch) => {
		const tmp = items.slice();
		tmp[position - 1].batch = batch;
		setItems(tmp);
	};

	const handleCostChange = (position, cost) => {
		const tmp = items.slice();
		tmp[position - 1].cost = cost || 0;
		setItems(tmp);
	};

	const handleStorageLocationChange = (position, storageLocation) => {
		const tmp = items.slice();
		tmp[position - 1].plantId = storageLocation[0];
		tmp[position - 1].storageLocationId = storageLocation[1];
		tmp[position - 1].storageLocation = storageLocation;
		setItems(tmp);
	};

	const handleQuantityChange = (position, quantity) => {
		const tmp = items.slice();
		tmp[position - 1].quantity = quantity;
		setItems(tmp);
	};

	const handleRemoveItem = position => {
		const tmp = items.filter(i => i.position !== position);
		// Re-index
		tmp.forEach((i, index) => {
			i.position = index + 1;

			return i;
		});
		setItems([...tmp]);
	};

	const cols = () => [
		{
			className: [2, 3].includes(selectedType) ? 'app-hiden' : '',
			render: record => (
				<MinusOutlined
					onClick={() => handleRemoveItem(record.position)}
					style={{ color: 'red' }}
				/>
			),
		},
		{ title: 'Posición', dataIndex: 'position', align: 'center', width: 80 },
		{ title: 'Categoría', dataIndex: 'category' },
		{ title: 'Producto', dataIndex: 'name' },
		{ title: 'Descripción', dataIndex: 'description' },
		{
			title: 'Cantidad',
			dataIndex: 'quantity',
			align: 'right',
			width: 80,
			render: (value, row) =>
				[2, 3].includes(selectedType) ? (
					value
				) : (
					<InputNumber
						style={{ width: '80px' }}
						min={0}
						value={value}
						onChange={value => handleQuantityChange(row.position, value)}
					/>
				),
		},
		{ title: 'Unidad Medida', dataIndex: 'baseUnit' },
		{
			title: 'Texto',
			dataIndex: 'text',
			render: (value, row) =>
				[2, 3].includes(selectedType) ? (
					value
				) : (
					<Input
						style={{ width: '150px' }}
						value={value}
						onChange={e => handleTextChange(row.position, e.target.value)}
					/>
				),
		},
		{
			className:
				['110', '120', '150', '280'].includes(selectedMovement) ||
					(selectedType === 2 &&
						movement &&
						[
							'110',
							'111',
							'120',
							'130',
							'140',
							'150',
							'220',
							'240',
							'280',
						].includes(movement.movementType.id))
					? ''
					: 'app-hiden',
			title: 'Costo Unitario',
			dataIndex: 'cost',
			render: (value, row) =>
				[2, 3].includes(selectedType) ? (
					numberFormat(value)
				) : (
					<InputNumber
						style={{ width: '120px', padding: '5px 0' }}
						min={0}
						defaultValue={0}
						value={value}
						formatter={value =>
							`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
						onChange={value => handleCostChange(row.position, value)}
					/>
				),
		},
		{
			title: 'Almacén',
			dataIndex: 'storageLocation',
			render: (value, row) =>
				[2, 3].includes(selectedType) ? (
					value
				) : (
					<Cascader
						style={{ width: '220px' }}
						placeholder=''
						options={plants.map(plantsCascade)}
						value={value}
						onChange={value => handleStorageLocationChange(row.position, value)}
					/>
				),
		},
		{
			title: 'Lote',
			dataIndex: 'batch',
			render: (value, row) =>
				[2, 3].includes(selectedType) ? (
					value
				) : (
					<Input
						style={{ width: '150px' }}
						value={value}
						onChange={e => handleBatchChange(row.position, e.target.value)}
					/>
				),
		},

	];

	const productsSelectedHandler = products => {
		let tmp = items;

		// Por cada producto seleccionado, si ya existe en la lista,
		// se aumentando la cantidad
		products.forEach(p => {
			const index = tmp.findIndex(i => i.id === p.id);

			// Agragamos propiedad productId
			p.productId = p.id;

			if (index === -1) tmp.push(p);
			else tmp[index].quantity += p.quantity;
		});

		// Position
		tmp = tmp.map((item, index) => ({ ...item, position: index + 1 }));

		setItems([...tmp]);
		setShowProductsModal(false);
	};

	const noMovimientoEnterHandler = async e => {
		if (e.key !== 'Enter' || !e.target.value) return;

		setTableLoading(true);
		try {
			const { data } = await getMovement(e.target.value);
			setMovement(data);
			setItems(
				data.movementDetails.map(i => ({
					...i,
					category: i.category.name,
					name: i.product.name,
					description: i.product.description,
					baseUnit: i.unitOfMeasure.unit,
					cost: i.unitCost,
					plant: i.plant.name,
					storageLocation: i.storageLocation.name,
					destinationPlant: i.destinationPlant && i.destinationPlant.name,
					destinationStorageLocation:
						i.destinationStorageLocation && i.destinationStorageLocation.name,
					costCenterId: i.costCenter && i.costCenter.id,
				}))
			);

			setTableLoading(false);
		} catch (error) {
			setTableLoading(false);
			setMovement(null);
			setItems([]);
			if (error.response && error.response.status === 404)
				notification.warning({
					message: 'Movimiento no encontrado!',
					description: 'Por favor verifique el número de movimiento',
				});
		}
	};

	const saveHandler = async () => {
		// If is to save template
		if (template) {
			// Validate data
			if (!validate()) return;

			setBtnLoading(true);

			try {
				const req = {
					active: template.active,
					name: template.name,
					movement: {
						movementTypeId: template.data.MovementTypeId,
						supplierId: template.data.SupplierId,
						details: items.map(i => ({
							position: i.position,
							quantity: i.quantity,
							categoryId: i.categoryId,
							productId: i.productId,
							unitOfMeasureId: i.baseUnit,
							text: i.text,
							costCenterId: i.costCenterId,
							plantId: i.plantId,
							storageLocationId: i.storageLocationId,
							batch: i.batch
						})),
					},
				};

				await updateTemplate(template.id, req);
				setBtnLoading(false);
				window.location = '/intelinet/plantillas';
			} catch (error) {
				setBtnLoading(false);
				if (error.response && error.response.status === 400)
					notification.warning({ description: error.response.data });
			}

			return;
		}

		// Entrada / Salida / Ajuste
		if ([1].includes(selectedType)) {
			// Validate
			if (!validate()) return;

			setBtnLoading(true);

			try {
				const request = {
					movementTypeId: selectedMovement,
					details: items.map(i => ({ ...i, unitOfMeasureId: i.baseUnit })),
				};

				const response = await createMovement(request);
				setItems([]);
				notification.success({
					duration: 0,
					message: 'Movimiento guardado!',
					description: `Número de movimiento: ${response.data.id}`,
				});
				setBtnLoading(false);
			} catch (error) {
				setBtnLoading(false);
				if (error.response && error.response.status === 400)
					notification.warning({ description: error.response.data });
			}
		}

		// Anular
		if (selectedType === 3) {
			try {
				const response = await cancelMovement(movement.id);
				setItems([]);
				notification.success({
					duration: 0,
					message: 'Movimiento anulado!',
					description: `Número movimiento de anulación: ${response.data.id}`,
				});
			} catch (error) {
				if (error.response && error.response.status === 400)
					notification.warning({
						message: '',
						description: error.response.data,
					});
			}
		}
	};

	const validate = () => {
		// Validar que sea seleccionado selectedMovement
		if (!selectedMovement) {
			notification.warning({
				description: 'Debe seleccionar el tipo de movimiento',
			});
			return false;
		}

		// Validar al menos un producto
		if (!items.length) {
			notification.warning({
				description: 'Debe agregar al menos un producto',
			});
			return false;
		}

		// Validar costos unitarios
		if (
			['110', '120', '150', '280'].includes(selectedMovement) &&
			items.some(i => !i.cost)
		) {
			notification.warning({
				description: 'Todos los productos deben tener Costo Unitario',
			});
			return false;
		}

		// Validar almacen
		if (
			![2, 3].includes(selectedType) &&
			(items.some(i => !i.storageLocationId) || items.some(i => !i.plantId))
		) {
			notification.warning({
				description: 'Todos los productos deben tener Almacén',
			});
			return false;
		}

		// Validar centros de costo
		if (
			['120', '220', '221'].includes(selectedMovement) &&
			items.some(i => !i.costCenterId)
		) {
			notification.warning({
				description: 'Todos los productos deben tener Centro Costo',
			});
			return false;
		}

		// Validar almacen destino
		if (
			['230', '240'].includes(selectedMovement) &&
			(items.some(i => !i.destinationStorageLocationId) ||
				items.some(i => !i.destinationPlantId))
		) {
			notification.warning({
				description: 'Todos los productos deben tener Almacén Destino',
			});
			return false;
		}

		return true;
	};

	return (
		<div className='main-container'>
			<div>
				<h1 className='title'>Movimientos</h1>
				<hr className='hr-title' />
			</div>
			<div className='detail-container'>
				<MovementsProductsModal
					visible={showProductsModal}
					onCancel={() => setShowProductsModal(false)}
					onProductsSelected={productsSelectedHandler}
				/>
				<div style={{ textAlign: 'right' }}>
					{tmpLoading ? (
						<Skeleton.Input style={{ width: 200 }} active={true} size='small' />
					) : (
						template && (
							<p
								style={{
									color: 'burlywood',
									fontStyle: 'italic',
								}}>{`Editando plantilla: ${template.name}`}</p>
						)
					)}
				</div>
				<Row gutter={[16, 16]}>
					<Col xs={24} sm={8} md={6} lg={5}>
						<Select
							disabled={template}
							value={selectedType}
							loading={loading}
							onChange={handleTypeChange}
							style={{ display: 'block' }}
							placeholder='Tipo de Movimiento'>
							{['Entrada', 'Visualizar', 'Anular'].map(
								(v, index) => (
									<Option key={index + 1} value={index + 1}>
										{v}
									</Option>
								)
							)}
						</Select>
					</Col>
					{[2, 3].includes(selectedType) && (
						<Col xs={24} sm={14} md={8} lg={6}>
							<Input
								placeholder='Número de Movimiento'
								prefix={<HiOutlineSwitchHorizontal />}
								onKeyUp={noMovimientoEnterHandler}
							/>
						</Col>
					)}
					{selectedMovement && (
						<Col className='rowDescMov2' xs={24} sm={24} md={24} lg={24}>
							<Row>
								<Col>
									<q>{descMovements[selectedMovement]}</q>
								</Col>
							</Row>
						</Col>
					)}
					{[1, 3].includes(selectedType) && (
						<Col xs={24} sm={10} md={4} lg={4}>
							<Button
								loading={btnLoading}
								icon={<SaveOutlined />}
								type='primary'
								onClick={saveHandler}>
								Guardar
							</Button>
						</Col>
					)}
				</Row>
				{[1].includes(selectedType) && (
					<Row style={{ marginTop: '16px' }}>
						<Col>
							<Button
								icon={<PlusOutlined />}
								type='primary'
								onClick={() => setShowProductsModal(true)}>
								Agregar productos
							</Button>
						</Col>
					</Row>
				)}
				{[2, 3].includes(selectedType) && movement && (
					<Row style={{ marginTop: '16px' }}>
						<Col span={24}>
							<Descriptions size='small' labelStyle={{ fontWeight: 'bold' }}>
								<Descriptions.Item label='Tipo de movimiento'>
									{movement.movementType.name}
								</Descriptions.Item>
								<Descriptions.Item label='Usuario'>
									{movement.user.userName}
								</Descriptions.Item>
								<Descriptions.Item label='Fecha'>
									{moment(movement.date).format(dateTimeFormat)}
								</Descriptions.Item>
							</Descriptions>
						</Col>
					</Row>
				)}
				<Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
					<Col span={24}>
						<Table
							loading={tableLoading}
							pagination={false}
							style={{ overflowX: 'auto' }}
							rowKey='position'
							size='small'
							columns={cols()}
							dataSource={items}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
}
