import './index.css';

import {
	CheckOutlined,
	EditOutlined,
	EyeOutlined,
	UnlockOutlined,
	LockOutlined,
} from '@ant-design/icons';
import { Col, Row, Card, Modal, Button, notification, Table } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TemplateRoom from './room';
import {
	getAllTemplates,
	applyTemplate,
	updateTemplate,
} from '../../services/templateService';

export default function Templates() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [plantillas, setPlantillas] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [plantilla, setPlantilla] = useState(null);
	const [loadings, setLoadings] = useState({ apply: false, enable: false });

	useEffect(() => {
		async function process() {
			setLoading(true);
			const { data } = await getAllTemplates();
			setPlantillas(data);
			setLoading(false);
		}

		process();
	}, []);

	const clickHandler = plantilla => {
		setPlantilla(plantilla);
		setShowModal(true);
	};

	const applyHandler = async () => {
		setLoadings({ ...loadings, apply: true });
		try {
			const { data } = await applyTemplate(plantilla.id);
			setShowModal(false);
			setLoadings({ ...loadings, apply: false });
			// TODO Link to
			notification.success({
				message: `Plantilla aplicada correctamente`,
				description: `Número de movimiento: ${data.id}`,
			});
		} catch (error) {
			setShowModal(false);
			setLoadings({ ...loadings, apply: false });
			if (error.response && error.response.status === 400)
				notification.warning({ description: error.response.data });
		}
	};

	const enableHandler = async enable => {
		setLoadings({ ...loadings, enable: true });
		const state = enable ? 'habilitada' : 'deshabilitada';

		try {
			await updateTemplate(plantilla.id, {
				...plantilla,
				movement: JSON.parse(plantilla.data),
				active: enable,
			});

			const tmp = [...plantillas];
			const plan = tmp.filter(p => p.id === plantilla.id)[0];
			plan.active = enable;
			setPlantillas(tmp);
			setShowModal(false);
			setLoadings({ ...loadings, enable: false });
			notification.success({
				message: `Plantilla '${plantilla.name}' ${state} correctamente`,
			});
		} catch (error) {
			setShowModal(false);
			setLoadings({ ...loadings, enable: false });
			notification.error({
				message: `La plantilla '${plantilla.name}' no pudo ser ${state}`,
			});
		}
	};

	const showDetailsModalHandler = () => {
		setShowModal(false);
		setShowDetailsModal(true);
	};

	return (
		<div className='mainContainer'>
			<Modal
				className='app-modal-template'
				title={`Plantilla: ${plantilla && plantilla.name}`}
				open={showModal}
				footer={null}
				onCancel={() => setShowModal(false)}>
				<Button
					type='default'
					icon={<EyeOutlined />}
					block
					onClick={showDetailsModalHandler}>
					Ver
				</Button>
				<Button
					type='default'
					icon={<EditOutlined />}
					block
					onClick={() => navigate(`/Intelinet/movimientos?template=${plantilla && plantilla.id}`)}>
					Editar
				</Button>
				{plantilla && plantilla.active ? (
					<>
						<Button
							type='default'
							icon={<LockOutlined />}
							block
							loading={loadings.enable}
							onClick={() => enableHandler(false)}>
							Deshabilitar
						</Button>
						<Button
							type='default'
							icon={<CheckOutlined />}
							block
							loading={loadings.apply}
							onClick={applyHandler}>
							Aplicar
						</Button>
					</>
				) : (
					<Button
						type='default'
						icon={<UnlockOutlined />}
						block
						loading={loadings.enable}
						onClick={() => enableHandler(true)}>
						Habilitar
					</Button>
				)}
			</Modal>
			<Modal
				title={`Plantilla: ${plantilla && plantilla.name}`}
				open={showDetailsModal}
				footer={null}
				onCancel={() => setShowDetailsModal(false)}>
				<Table
					rowKey='Position'
					pagination={false}
					dataSource={
						plantilla
							? _.orderBy(JSON.parse(plantilla.data).Details, 'Position')
							: []
					}
					columns={[
						{ width: 80, title: 'Cantidad', dataIndex: 'Quantity' },
						{ title: 'Producto', dataIndex: 'Name' },
					]}
				/>
			</Modal>
			<div>
				<h1 className='title'>Plantillas</h1>
				<hr className='hr-title' />
			</div>
			<div className='detailContainer' style={{ overflowX: 'auto' }}>
				<Row gutter={[16, 16]}>
					{loading ? (
						[1, 2, 3, 4].map(p => (
							<Col xs={12} sm={8} md={6} key={p}>
								<Card loading={true} />
							</Col>
						))
					) : (
						<div>
							<h3 className='app-templates-x-title'>HABITACIÓN</h3>
							<div style={{ display: 'flex', width: '1600px' }}>
								<h3 className='app-templates-y-title'>PISO</h3>
								<div>
									{_.map(
										_.groupBy(plantillas, p => p.name[0]),
										(items, key) => (
											<div key={key} className='app-templates-level'>
												{_.orderBy(items, 'name').map(p => (
													<TemplateRoom
														key={p.id}
														title={p.name}
														disabled={!p.active}
														onClick={() => clickHandler(p)}
													/>
												))}
											</div>
										)
									)}
								</div>
							</div>
						</div>
					)}
				</Row>
			</div>
		</div>
	);
}
