import axios from 'axios';
import { notification } from 'antd';

const client = axios.create({
	baseURL: '/api/v1',
});

client.interceptors.response.use(null, error => {
	const expectedError =
		error.response &&
		error.response.status >= 400 &&
		error.response.status < 500;

	if (!expectedError) {
		notification.error({
			message: 'Sistema',
			description: 'Ha ocurrido un error inesperado!',
		});
	}

	return Promise.reject(error);
});

export function setAuthorization(token) {
	if (token) client.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function deleteAuthorization() {
	delete client.defaults.headers.common.Authorization;
}

export function setDefaultHeaders(customer, organization) {
	if (customer) client.defaults.headers.common.customerId = customer;
	if (organization)
		client.defaults.headers.common.organizationId = organization;
}

export default {
	get: client.get,
	post: client.post,
	put: client.put,
	delete: client.delete,
	patch: client.patch,
	setAuthorization,
	deleteAuthorization,
	setDefaultHeaders,
};
