import { Typography, Space } from 'antd';
import React from 'react';

export default function UnAuthorized() {
	return (
		<Space
			direction='horizontal'
			style={{ width: '100%', justifyContent: 'center', padding: '1rem' }}>
			<Typography.Title level={1}>
				No tienes autorización para ver esta página.
			</Typography.Title>
		</Space>
	);
}
