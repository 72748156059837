import React, { useState, useEffect } from "react";
import { Button, Row, Col, Tooltip, Table, Input } from 'antd';
import { FaPlusCircle, FaPencilAlt } from 'react-icons/fa';
import { getAll } from '../../services/planService';
import { normalized } from "../../utils/string";
import auth from "../../services/authService";

import PlanModal from "./PlanModal";

function Plan() {
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [plan, setPlan] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [tempDataTable, setTempDataTable] = useState([]);


    useEffect(() => {
        getDataTable();
    }, []);

    const getDataTable = async () => {
        setTableLoading(true);
        try {
            const { data } = await getAll();

            setDataTable(data);
            setTempDataTable(data);
        } finally {
            setTableLoading(false);
        }
    }

    const handleClickModalNew = () => {
        setPlan(null);
        setModalVisible(true);
    }

    const handleClickModalEdit = (record) => {
        setPlan(record);
        setModalVisible(true);
    }

    const handleOk = (item) => {
        const tempData = [...dataTable];
        if (plan) {
            const indx = tempData.findIndex(n => n.id === item.id);
            tempData[indx] = { ...tempData[indx], ...item };
        } else {
            tempData.unshift(item);
        }

        setDataTable(tempData);
        setTempDataTable(tempData);
        setModalVisible(false);
    }

    const handleModalCancel = () => {
        setPlan(null);
        setModalVisible(false);
    }

    const handleSearch = (e) => {
        const currValue = e.target.value;
        setSearchValue(currValue);
        if (!currValue)
            return setDataTable(tempDataTable);

        const filteredData = tempDataTable.filter(entry =>
            normalized(entry.productId.toString()).includes(normalized(currValue)) ||
            normalized(entry.name).includes(normalized(currValue))
        );
        setDataTable(filteredData);
    };

    const columns = [
        { title: 'ID', dataIndex: 'productId' },
        { title: 'Nombre', dataIndex: 'name' },
        { title: 'Precio', dataIndex: 'price' },
        { title: 'Días descuento', dataIndex: 'discountDays' },
        { title: 'Monto descuento', dataIndex: 'discountAmount' },
        {
            title: '', align: 'center',
            render: (_, record) => (
                <Tooltip title="Editar">
                    <Button type="primary" shape="circle" onClick={() => handleClickModalEdit(record)}
                        icon={<FaPencilAlt />} disabled={!auth.isInRoles('PLAN_UPDATE')} />
                </Tooltip>)
        },
    ];

    return <div className="main-container">
        <PlanModal visible={modalVisible}
            plan={plan} onOk={handleOk}
            onCancel={handleModalCancel}
            dataPlans={dataTable}
        />
        <Row>
            <Col span={24}>
                <h1 className="title">Planes</h1>
                <hr className="hr-title" />
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                <div className="div-right">
                    <Button type="primary" icon={<FaPlusCircle />} shape="round" onClick={handleClickModalNew}
                        disabled={!auth.isInRoles('PLAN_CREATE')}>
                        &nbsp; Nuevo
                    </Button>
                </div>
            </Col>
            <Col span={6}>
                <br />
                <Input
                    placeholder="Buscar"
                    value={searchValue}
                    onChange={handleSearch} />
            </Col>
            <Col span={24}>
                <br />
                <Table rowKey="productId" dataSource={dataTable} columns={columns} loading={tableLoading} />
            </Col>
        </Row>
    </div>
}

export default Plan;