import { useQuery } from "@tanstack/react-query";

import { getResults } from "../services/characteristicsService";

const useCharacteristicResults = (objectId, enabled = true) => useQuery({
    queryKey: ['characteristics', 'result', objectId],
    queryFn: () => getResults(objectId).then(res => res.data),
    enabled
});

export default useCharacteristicResults;