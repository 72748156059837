import {
	Modal,
	Row,
	Col,
	notification,
	Button,
	Select,
	Tooltip,
	Table,
	Switch,
	Input
} from 'antd';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';

export default function ModalInput(props) {
	const { Option } = Select;
	const [optVisible, setoptVisible] = useState(false);
	const [rangoVisible, setRangoVisible] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(false);
	const [dataTable, setDataTable] = useState([]);
	const [indicador, setIndicador] = useState(true);
	const [opt, setOpt] = useState('');
	const [rango1, setRango1] = useState('');
	const [rango2, setRango2] = useState('');
	const [operator, setOperator] = useState('');

	const handleChangeOperation = value => {
		setOperator(value);
		if (value === 'BT') {
			setoptVisible(false);
			setRangoVisible(true);
			setButtonVisible(true);
		} else {
			setoptVisible(true);
			setRangoVisible(false);
			setButtonVisible(true);
		}
	};

	const handleChangeOpt = value => {
		setOpt(value.target.value);
	};

	const handleChangeRange1 = value => {
		setRango1(value.target.value);
	};

	const handleChangeRange2 = value => {
		setRango2(value.target.value);
	};

	const handleAgregar = () => {
		if (optVisible) {
			if (opt === '') {
				notification.info({ message: 'Filtro', description: 'Escribe una opción para agregarla.' });

			} else {
				if (props.dataValidate)
					if (!props.dataValidate?.includes(opt))
						return notification.info({ message: 'Opciones validas', description: props.dataValidate.toString() });

				const dataF = [...dataTable];
				dataF.push({
					key: dataF.length + 1,
					option: operator,
					sign: indicador ? 'I' : 'E',
					low: opt,
					high: null,
				});
				setDataTable(dataF);
				setOpt("");
			}
		}
		if (rangoVisible) {
			if (rango1 === '' && rango2 === '') {
				notification.info({ message: 'Filtro', description: 'Escribe un rango para agregar.' });

			} else {
				if (props.dataValidate && (!props.dataValidate?.includes(rango1) || !props.dataValidate?.includes(rango2)))
					return notification.info({ message: 'Opciones validas', description: props.dataValidate.toString() });

				const dataF = [...dataTable];
				dataF.push({
					key: dataF.length + 1,
					option: operator,
					sign: indicador ? 'I' : 'E',
					low: rango1 > rango2 ? rango2 : rango1,
					high: rango1 > rango2 ? rango1 : rango2,
				});
				setDataTable(dataF);
				setRango1("");
				setRango2("");
			}
		}
	};

	const handleClickDelete = record => {
		const dataF = [...dataTable];
		const data = dataF.filter(x => x.key !== record.key);
		setDataTable(data);
	};

	const onChangeIndicator = checked => {
		setIndicador(checked);
	};

	const columns = [
		{
			title: 'Indicador',
			dataIndex: 'sign',
		},
		{
			title: 'Operación',
			dataIndex: 'option',
		},
		{
			title: 'Opción',
			render: (value, record) =>
				record.high == null ? record.low : record.low + ' - ' + record.high,
		},
		{
			title: '',
			align: 'center',
			render: (value, record) => (
				<React.Fragment>
					<Tooltip title='Quitar'>
						<Button
							type='primary'
							shape='circle'
							onClick={() => handleClickDelete(record)}
							icon={<IoMdCloseCircle />}
						/>
					</Tooltip>
				</React.Fragment>
			),
		},
	];

	return (
		<Modal
			title={props.title}
			open={props.visible}
			onOk={() => props.handleOkModalCat(dataTable)}
			okText="Tomar"
			onCancel={props.handleCancelModalCat}
			cancelText="Cancelar">
			<Row>
				<Col span={24}>
					<p>
						<FaInfoCircle /> Indicadores : I = Incluir, E = Excluir.
					</p>
				</Col>
				<Col span={4}>
					<Switch
						onChange={onChangeIndicator}
						checkedChildren='I'
						unCheckedChildren='E'
						defaultChecked
					/>
				</Col>
				<Col span={20}>
					<Select
						style={{ width: '100%' }}
						className='cbx-widht'
						onChange={handleChangeOperation}
						placeholder='Selecciona la operación...'>
						<Option value='EQ'>EQ - Igual a</Option>
						<Option value='BT'>BT - Entre </Option>
						<Option value='NE'>NE - No igual a</Option>
						<Option value='GE'>GE - Mayor igual a</Option>
						<Option value='LE'>LE - Menor igual a</Option>
						<Option value='GT'>GT - Mayor que</Option>
						<Option value='LT'>LT - Menor que</Option>
					</Select>
				</Col>
				<Col span={12} className={'collapse' + (optVisible ? 'in' : '')}>
					<Input className='mrg-2' value={opt} onChange={handleChangeOpt} placeholder="Nombre" />
				</Col>
				<Col span={11} className={'collapse' + (rangoVisible ? 'in' : '')}>
					<Input className='mrg-2' value={rango1} onChange={handleChangeRange1} placeholder="Nombre" />
				</Col>
				<Col span={2} className={'collapse' + (rangoVisible ? 'in' : '')}></Col>
				<Col span={11} className={'collapse' + (rangoVisible ? 'in' : '')}>
					<Input className='mrg-2' value={rango2} onChange={handleChangeRange2} placeholder="Nombre" />
				</Col>
				<Col span={2} className={'collapse' + (optVisible ? 'in' : '')}></Col>
				<Col
					span={rangoVisible ? 24 : 10}
					className={'collapse' + (buttonVisible ? 'in' : '')}>
					<Button
						className='mrg-2'
						block
						type='primary'
						icon={<IoMdCheckmarkCircle />}
						onClick={handleAgregar}>
						&nbsp; Agregar
					</Button>
				</Col>
				<Col span={24} className='main-container'>
					<Table rowKey='key' dataSource={dataTable} columns={columns} />
				</Col>
			</Row>
		</Modal>
	);
}
