import {
	Col,
	Row,
	Typography,
	Radio,
	Table,
	Button,
	Tooltip,
	Input,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaPencilAlt, FaPlusCircle } from 'react-icons/fa';
import config from '../../config.json';
import { useNavigate } from 'react-router';
import { getContracts } from '../../services/contractService';
import { normalized } from '../../utils/string';
import StyledRadio from '../../components/styledRadio';
import auth from "../../services/authService";

const PAGE_SIZE = 4000;

export default function Services() {
	const navigate = useNavigate();
	const { Title } = Typography;
	const [loading, setLoading] = useState(false);
	const [statusId, setStatusId] = useState('');
	const [contracts, setContracts] = useState([]);
	const [allContracts, setAllContracts] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [statuses, setStatuses] = useState([
		{
			color: '#FF8C00',
			text: 'Todos',
			status: '',
			reload: false,
			rol: 'SERVICE_FILTER_ALL'
		},
		{
			color: '#69B5EC',
			text: 'Solicitudes',
			status: config.statusContract.solicitado,
			reload: false,
			rol: 'SERVICE_FILTER_SOL'
		},
		{
			color: '#228B22',
			text: 'Instalado',
			status: config.statusContract.instalado,
			reload: false,
			rol: 'SERVICE_FILTER_INS'
		},
		{
			color: '#00FFFF',
			text: 'Instalación Aprobada',
			status: config.statusContract.instalacionAprobada,
			reload: false,
			rol: 'SERVICE_FILTER_IAP'
		},
		{
			color: '#A52A2A',
			text: 'Instalación Rechazada',
			status: config.statusContract.instalacionRechazada,
			reload: false,
			rol: 'SERVICE_FILTER_IRE'
		},
		{
			color: '#36EF80',
			text: 'Activo',
			status: config.statusContract.activo,
			reload: false,
			rol: 'SERVICE_FILTER_ACT'
		},
		{
			color: '#E8D850',
			text: 'Suspendido',
			status: config.statusContract.suspendido,
			reload: false,
			rol: 'SERVICE_FILTER_SUS'
		},
		{
			color: '#EC2A2A',
			text: 'Cancelado',
			status: config.statusContract.cancelado,
			reload: false,
			rol: 'SERVICE_FILTER_CAN'
		},
	]);
	const columns = [
		{
			title: 'Folio servicio',
			dataIndex: 'folio',
			key: 'folio',
		},
		{
			title: 'Cliente',
			dataIndex: 'client',
			key: 'client',
		},
		{
			title: 'Domicilio',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Plan',
			dataIndex: 'plan',
			key: 'plan',
		},
		{
			title: 'Estatus',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Motivo',
			dataIndex: 'reason',
			key: 'reason',
		},
		{
			title: 'Fecha alta',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: 'Editar',
			key: 'modify',
			render: (_, row) => (
				<Tooltip title='Editar'>
					<Button
						type='primary'
						onClick={() => {
							navigate(`/intelinet/servicios/contrato/${row.folio}`)
						}}
						icon={<FaPencilAlt />}
						shape='circle'
						disabled={!auth.isInRoles('SERVICE_UPDATE')}
					/>
				</Tooltip>
			),
		},
	];

	/**
	 * TODO Make an api for get contract statuses
	 */

	const handleSearch = event => {
		const { value } = event.target;
		setSearchTerm(value);
		if (!value) return;

		let temp = [...allContracts];
		if (statusId)
			temp = temp.filter(x => x.statusId === statusId);
		const filteredContracts = temp.filter(
			entry =>
				normalized(entry.client).includes(normalized(value)) ||
				normalized(entry.folio.toString()).includes(normalized(value)) ||
				normalized(entry.address).includes(normalized(value)) ||
				normalized(entry.plan).includes(normalized(value)) ||
				normalized(entry.status).includes(normalized(value)) ||
				normalized(entry.date).includes(normalized(value))
		);

		setContracts(filteredContracts);
	};

	useEffect(() => {
		fillTable();
	}, []);

	const fillTable = async function fillTable() {
		setLoading(true);
		try {
			const { data } = await getContracts(statusId, PAGE_SIZE, 0);

			const table = data.map((contract) => ({
				key: contract.contractId,
				folio: contract.contractId,
				client: `${contract.clientName} ${contract.clientLastName}${contract.clientMotherLastName ? ' ' + contract.clientMotherLastName : ''}`,
				address: `${contract.street} ${contract.numberExternal}`,
				plan: contract.productName,
				status: contract.lastStatusName,
				statusId: contract.contractStatusId,
				reason: contract.lastStatusComments,
				date: contract.lastStatusCreationDate
					? moment(contract.lastStatusCreationDate).format(config.dateFormat)
					: 'No disponible',
			}));

			setAllContracts(table);
		} finally {
			setLoading(false);
		}
	};

	const handleRadioChange = event => {
		const value = event.target.value;
		const temp = [...allContracts];
		setStatusId(`${value}`);
		if (!value)
			return setContracts(temp);
		const filter = temp.filter(x => x.statusId === value);
		setContracts(filter);
	};

	return (
		<>
			<Row justify='space-between' style={{ margin: '2rem' }}>
				<Col span={4}>
					<Title level={2}>Servicios</Title>
				</Col>

				<Col span={4}>
					<Button
						type='primary'
						size='large'
						icon={<FaPlusCircle />}
						onClick={() => {
							navigate('/intelinet/servicios/contrato')
						}}
						disabled={!auth.isInRoles('SERVICE_CREATE')}>
						Nuevo
					</Button>
				</Col>
			</Row>
			<Row>
				<Radio.Group
					style={{ margin: '2rem', width: '100%' }}
					onChange={handleRadioChange}>
					<Row gutter={16} justify='space-between'>
						{statuses.map(function (row) {
							if(auth.isInRoles(row.rol))
							return <Col key={row.status}>
								<StyledRadio
									color={row.color}
									text={row.text}
									status={row.status}
									reload={row.reload}
									statuses={statuses}
									setStatuses={setStatuses}
								/>
							</Col>
							
							return false;
						})}
					</Row>
				</Radio.Group>
			</Row>
			<Row>
				<Col span={6} style={{ marginLeft: '2rem', marginBottom: '1rem' }}>
					<Input
						placeholder='Buscar'
						value={searchTerm}
						onChange={handleSearch}
					/>
				</Col>
			</Row>
			<Row style={{ margin: '2rem' }}>
				<Col span={24}>
					<Table
						dataSource={contracts}
						columns={columns}
						loading={loading}
					/>
				</Col>
			</Row>

		</>
	);
}
