import { Modal, Input, Row, Col, Table, Form, notification } from 'antd';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { getProductStorageLocations, addToStorageLocation } from '../../services/CategoriesService';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};

	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleSave({ ...record, ...values });
		} catch (errInfo) { }
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{
					margin: 0,
				}}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} es requerido.`,
					},
				]}>
				<Input ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className='editable-cell-value-wrap'
				style={{
					paddingRight: 24,
				}}
				onClick={toggleEdit}>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

export default function ProductModalStorage(props) {
	const [dataTableStorage, setDataTableStorage] = useState([]);
	const [tableStorageLoading, setTableStorageLoading] = useState(false);
	const [selectedRowKeysStorage, setSelectedRowKeysStorage] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!props.product) return;
		async function prodStorage() {
			setTableStorageLoading(true);
			const { data } = await getProductStorageLocations(
				props.product.category.id,
				props.product.id
			);
			const keysStorage = [];
			const dataStorage = props.plant.storageLocations.map(function (obj) {
				const row = {
					id: obj.id,
					storageLocationId: obj.id,
					almacen: obj.name,
					stock: 0,
					safeStock: 0,
					inBatchsStock: 0,
					stockInTransit: 0
				};
				const ps = data.filter(p => p.storageLocationId === obj.id);
				if (ps.length > 0) {
					row.stock = ps[0].stock;
					row.safeStock = ps[0].safeStock;
					row.inBatchsStock = ps[0].inBatchsStock;
					row.stockInTransit = ps[0].stockInTransit;
					keysStorage.push(obj.id);
				}
				return row;
			});
			setSelectedRowKeysStorage(keysStorage);
			setDataTableStorage(dataStorage);
			setTableStorageLoading(false);
		}
		prodStorage();

	}, [props.product]);

	const handleOk = async () => {
		setLoading(true);
		const data = [...selectedRowKeysStorage];
		const datast = [...dataTableStorage];
		const storages = [];
		data.map(function (obj) {
			const item = datast.filter(x => x.id === obj);
			if (item.length > 0) {
				const row = {
					StorageLocationId: item[0].storageLocationId,
					Stock: item[0].stock,
					SafeStock: item[0].safeStock,
				};
				storages.push(row);
			}
			return true;
		});

		const request = {
			plantId: props.plant.id,
			categoryId: props.product.category.id,
			costCategoryId: props.costCategory.id,
			storageLocations: storages,
		};

		try {
			await addToStorageLocation(props.product.category.id, props.product.id, request);
			notification.success({ message: 'Almacén', description: 'Se actualizaron los almacenes.', });

		} catch (error) {
			if (error.response && error.response.status === 400)
				notification.warning({ description: error.response.data });
		}
		setLoading(false);
		props.onOkStorage();
	};

	const handleSave = row => {
		if (parseFloat(row.stock) >= 0 && parseFloat(row.safeStock) >= 0) {
			row.stock = parseFloat(row.stock);
			row.safeStock = parseFloat(row.safeStock);
			const newData = [...dataTableStorage];
			const index = newData.findIndex(item => row.id === item.id);
			const item = newData[index];
			newData.splice(index, 1, { ...item, ...row });
			setDataTableStorage(newData);
		}
	};

	const columns = [
		{
			title: 'Información',
			render: record => (
				<>
					{record.id}
					<br />
					{record.almacen}
				</>
			),
			responsive: ['xs'],
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			responsive: ['sm'],
		},
		{
			title: 'Almacén',
			dataIndex: 'almacen',
			responsive: ['sm'],
		},
		{
			title: 'Stock',
			dataIndex: 'stock',
		},
		{
			title: 'Stock de seguridad',
			dataIndex: 'safeStock',
			editable: true,
		},
		{
			title: 'Stock en lotes',
			dataIndex: 'inBatchsStock',
		},
		{
			title: 'Stock en transito',
			dataIndex: 'stockInTransit',
		},
	];

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const columnss = columns.map(col => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	const onSelectChangeStorage = selectedRowKeys => {
		// Unselected items with stock
		const unselected = selectedRowKeysStorage.filter(
			x =>
				!selectedRowKeys.includes(x) &&
				dataTableStorage.some(dts => dts.id === x && dts.stock !== 0)
		);

		// Select again unselected items with stock
		setSelectedRowKeysStorage([...selectedRowKeys, ...unselected]);

		// If there are not unselected items with stock, exit method.
		if (!unselected.length) return;

		notification.warning({
			message: 'Producto',
			description:
				'No es posible deshabilitar productos de almacén cuando el stock es diferente de 0.',
		});
	};

	const rowSelectionStorage = {
		selectedRowKeys: selectedRowKeysStorage,
		onChange: onSelectChangeStorage,
	};

	return (
		<>
			<Modal
				title='Almacenes del producto'
				open={props.visible}
				confirmLoading={loading}
				onOk={handleOk}
				okText="Guardar"
				onCancel={() => props.onCancelStorage()}
				cancelText="Cancelar"
				width={800}>
				<Row>
					<Col span={24}>
						<Input value={props.product && props.product.name} placeholder='Nombre' disabled />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Table
							className='mrg-2'
							rowKey='id'
							components={components}
							rowClassName={() => 'editable-row'}
							dataSource={dataTableStorage}
							columns={columnss}
							rowSelection={rowSelectionStorage}
							loading={tableStorageLoading}
						/>
						<p>
							<FaInfoCircle /> Para modificar la cantidad debes dar clic en la
							celda que contiene el valor.
						</p>
						<p>
							<FaInfoCircle /> Los almacenes seleccionados son los que estan
							asignados al producto.
						</p>
					</Col>
				</Row>
			</Modal>
		</>
	);
}
