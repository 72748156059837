import moment from 'moment';
import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

export default function ProtectedRoute({ roles, children }) {
	const { logout, isUserAuthenticated, user, userRoles } = useAuth();

	if (!isUserAuthenticated) {
		logout();
		return <Navigate to='intelinet/login' replace />;
	}

	const expiration = moment.unix(user.exp);
	const tokenIsExpired = moment() > expiration;

	if (tokenIsExpired) {
		logout();
		return <Navigate to='intelinet/login' replace />;
	}

	if (roles && !userRoles.some(role => roles.includes(role)))
		return <Navigate to='/un-authorized' replace />;

	return children;
}
