import React, { useState } from 'react'
import { Modal, Row, Col, notification, Table } from 'antd';

function PaymentContractsModal(props) {
    const [contract, setContract] = useState(null);

    const handleOk = () => {
        if (!contract)
            return notification.info({ message: 'Adelantar factura', description: 'Seleccióna un servicio.', });
        props.onOk(contract);
    };

    const handleCancel = () => {
        props.onCancel();
    }

    const rowSelection = {
        type: 'radio',
        onChange: (selectedRowKeys) => {
            setContract(selectedRowKeys);
        },
    };

    const fullAddress = (item) => `${item.street} ${item.numberExternal}`;

    const columns = [
        { title: 'No. servicio', dataIndex: 'contractId' },
        { title: 'Descripción', dataIndex: 'productName' },
        { title: 'Domicilio', render: (_, record) => fullAddress(record) },
    ];

    return <Modal title="Contratos"
        open={props.visible}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Row>
            <Col span={24}>
                <Table rowKey="contractId" rowSelection={rowSelection} dataSource={props.contracts} columns={columns} pagination={{ hideOnSinglePage: true }} />
            </Col>
        </Row>
    </Modal>
}

export default PaymentContractsModal;