import http from "./httpService";

const endPoint = "/Clients";

export function createCustomer(request) {
    return http.post(endPoint, request);
}

export function getAllCustomer(take, skip) {
    return http.get(`${endPoint}/GetAll`, { params: { take, skip } });
}

export function getCustomer(id) {
    return http.get(`${endPoint}/${id}`);
}

export function getCustomers() {
    return http.get(endPoint);
}

export function updateCustomer(id, request) {
    return http.put(`${endPoint}/${id}`, request);
}

export function getContract(clientId, statusId) {
    return http.get(`${endPoint}/${clientId}/Contracts`, { params: { statusId } });
}

export default {
    createCustomer,
    getAllCustomer,
    updateCustomer,
    getCustomer,
    getCustomers,
    getContract
};