import 'antd/dist/antd.css';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './custome.css';
import './antStyle.css';
import config from './config.json'

Sentry.init({ ...config.sentry, integrations: [new BrowserTracing()] });

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const queryClient = new QueryClient();

const root = createRoot(rootElement);
root.render(<BrowserRouter basename={baseUrl}>
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools />
  </QueryClientProvider>
</BrowserRouter>);

registerServiceWorker();
