import React  from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";

import config from "../../config.json";
import useDeleteSuspension from '../../hooks/useDeleteSuspension';

const SuspensionsTable = (props) => {
    const remove = useDeleteSuspension({ 
        onError: () => notification.error({ description: 'No fue posible eliminar la suspensión.'})
    });

    const columns = [
        {
            dataIndex: ["contractStatusType", "name"],
            title: "Tipo de suspensión"
        },
        {
            dataIndex: "startDate",
            title: "Inicio",
            render: (value) => moment(value).format(config.dateFormat),
            sorter: (a, b) => moment(a.startDate) - moment(b.startDate),
            defaultSortOrder: 'descend',
            width: 100
        },
        {
            dataIndex: "endDate",
            title: "Fin",
            render: (value) => value && moment(value).format(config.dateFormat),
            width: 100
        },
        {
            dataIndex: "comments",
            title: "Comentarios"
        },
        {
            dataIndex: "creationDate",
            title: "Fecha",
            render: (value) => moment(value).format(config.dateTimeFormat),
            sorter: (a, b) => moment(a.creationDate) - moment(b.creationDate),
            width: 180
        },
        {
            dataIndex: ["user", "userName"],
            title: "Usuario"
        },
        {
            title: "",
            render: (_, record) => <Popconfirm 
                title="¿Está seguro que desea eliminar la suspensión？"
                okText="Si"
                cancelText="No"
                disabled={disableRemove(record)}
                onConfirm={() => remove.mutate({ contractId: props.contractId, code: record.code })}>
                <Tooltip title="Eliminar">
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} disabled={disableRemove(record)} />
                </Tooltip>
            </Popconfirm>
        }
    ]

     // Removing suspensions are not allowed when suspension is...
    const disableRemove = ({ startDate, endDate }) =>
        (new Date() > new Date(startDate) && new Date() < new Date(endDate)) || // in progress
        new Date() > new Date(endDate);                                         // or over

    return <Table
        rowKey="code"
        columns={columns}
        style={{ overflowX: "scroll" }}
        pagination={{ hideOnSinglePage: true }}
        {...props} />;
};

export default SuspensionsTable;