import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Select, notification, Popconfirm, Popover } from 'antd';
import { FaMoneyBill, FaFileInvoiceDollar, FaHandHoldingUsd, FaBuilding, FaPencilAlt } from "react-icons/fa";
import { BiNotepad, BiBlock } from "react-icons/bi";
import { normalized } from '../../utils/string';
import { getCustomers, getContract } from '../../services/customerService';
import { advancePayment, getMovements } from '../../services/paymentService';
import { getSalesOfficesMy } from '../../services/salesOfficeService';
import { checkFutureLayOffs } from '../../services/contractService';
import moment from 'moment';
import '../../etiquetaPrint.css';
import config from '../../config.json';
import auth from "../../services/authService";

import PaymentBonusModal from './modalBonus';
import PaymentModal from './modalPayment';
import Ticket from '../../components/ticket';
import PaymentContractsModal from './modalContracts';
import NotaCargoModal from './modalNotaCargo';
import SalesOfficeModal from '../../components/modalSalesOffice';

const { Option } = Select;

function Payment() {
    const [dataTable, setDataTable] = useState([]);
    const [dataSalesOffice, setDataSalesOffice] = useState([]);
    const [tempDataTable, setTempDataTable] = useState([]);
    const [dataCustomers, setDataCustomers] = useState([]);
    const [dataContracts, setDataContracts] = useState([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [facturaLoading, setFacturaLoading] = useState(false);
    const [modalBonusVisible, setModalBonusVisible] = useState(false);
    const [modalPeymentVisible, setModalPeymentVisible] = useState(false);
    const [modalContractsVisible, setModalContractsVisible] = useState(false);
    const [modalNotaCargoVisible, setModalNotaCargoVisible] = useState(false);
    const [modalSalesOfficeVisible, setModalSalesOfficeVisible] = useState(false);
    const [service, setService] = useState(null);
    const [contract, setContract] = useState(null);
    const [serviceBonus, setServiceBonus] = useState(null);
    const [disBonusButtons, setDisBonusButtons] = useState(true);
    const [disPeyButtons, setDisPeyButtons] = useState(true);
    const [disSelect, setDisSelect] = useState(true);
    const [customer, setCustomer] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [accountingId, setAccountingId] = useState(null);
    const [efectivo, setEfectivo] = useState(null);
    const [cambio, setCambio] = useState(null);
    const [open, setOpen] = useState(false);
    const [openNC, setOpenNC] = useState(false);
    const [typeOp, setTypeOp] = useState(null);
    const [salesOffice, setSalesOffice] = useState([]);
    const [btnEditOfficeVisible, setBtnEditOfficeVisible] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);

    useEffect(() => {
        async function dtable() {
            setSelectLoading(true);
            try {
                const { data } = await getCustomers();
                if (data) {
                    const fdata = data.map((item) => (
                        {
                            ...item,
                            completeName: `${item.name} ${item.lastName} ${item.motherLastName}`,
                        }
                    ));
                    const fdorder = fdata.sort((a, b) => {
                        const nameA = a.completeName.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.completeName.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        // names must be equal
                        return 0;
                    });
                    setDataCustomers(fdorder);
                }

            } finally {
                setSelectLoading(false);
            }
        }
        async function getSalesOffice() {
            setDisSelect(true);
            const { data } = await getSalesOfficesMy();
            setDataSalesOffice(data);
            setDisSelect(false);
            if (data.length > 1) {
                setBtnEditOfficeVisible(true);
                return setModalSalesOfficeVisible(true);
            }

            setSalesOffice(data[0]);
        }
        getSalesOffice();
        dtable();
    }, []);

    const handleSetContract = (msj, op) => {
        setTypeOp(op);
        if (dataContracts.length > 1)
            setModalContractsVisible(true);
        if (dataContracts.length === 1 && op === "AF")
            advanceInvoice(dataContracts[0].contractId);
        if (dataContracts.length === 1 && op === "NC")
            handleShowNotaCargoModal(dataContracts[0].contractId);
        if (dataContracts.length === 0)
            notification.info({ message: msj, description: `No se encontró un servicio activo para ${msj}.`, });
        if (op === "AF")
            setOpen(false);
        if (op === "NC")
            setOpenNC(false);
    }

    const advanceInvoice = async (contractId) => {
        setFacturaLoading(true);
        try {
            await advancePayment(contractId, salesOffice.id);
            getDataTable(customer);
            notification.success({ message: 'Adelantar factura', description: 'Se aplicó el adelanto de factura.', });
        } catch (error) {
            if (error.response && error.response.status === 400)
                notification.warning({ message: 'Adelantar factura', description: error.response.data });
        } finally {
            setOpen(false);
            setFacturaLoading(false);
        }
    };

    const handleShowNotaCargoModal = (contractId) => {
        setContract(dataContracts.filter(c => c.contractId === contractId)[0]);
        setModalNotaCargoVisible(true);
    }

    const handleCancelPopconfirm = (op) => {
        if (op === "AF")
            setOpen(false);
        if (op === "NC")
            setOpenNC(false);
        setModalContractsVisible(false);
    }

    const handleModalOkContracts = (contractId) => {
        if (typeOp === "AF")
            advanceInvoice(contractId);
        if (typeOp === "NC")
            handleShowNotaCargoModal(contractId[0]);
        setModalContractsVisible(false);
    };

    const handleModalOkNotaCargo = () => {
        getDataTable(customer);
        setModalNotaCargoVisible(false);
    }

    const handleModalCancelContracts = () => {
        setModalContractsVisible(false);
        setOpen(false);
    };

    const handleModalOkSalesOffice = (office) => {
        setSalesOffice(office);
        setModalSalesOfficeVisible(false);
    }

    const handleClickNewBonus = () => {
        const debit = tempDataTable.find(n => n.id === selectedRowKeys[0]);
        const sumCredit = tempDataTable
            .reduce((acu, cur) => cur.parentId === debit.accountingId &&
                cur.parentDetailId === debit.accountingDetailId
                ? acu + cur.total : acu,
                0);
        const doc = {
            ...debit,
            totalDebit: debit.total - sumCredit
        }
        setServiceBonus(doc);
        setModalBonusVisible(true);
    };

    const handleModalCancelBonus = () => {
        setModalBonusVisible(false);
    };

    const handleModalOkBonus = () => {
        getDataTable(customer);
        setModalBonusVisible(false);
    };

    const handleClickNewPeyment = async () => {
        try {
            setLoadingPayment(true);
            const debit = tempDataTable.filter(n => selectedRowKeys.includes(n.id));
            const contracts = debit.map(({ contractId }) => contractId);
            const distinctContracts = [...new Set(contracts)];
            await checkFutureLayOffs(distinctContracts);
            const doc = debit.map((element) => {
                const sumWithInitial = tempDataTable.filter(c => c.parentId === element.accountingId &&
                    c.parentDetailId === element.accountingDetailId).reduce(
                        (accumulator, currentValue) => accumulator + currentValue.total, 0);
                element.credit = sumWithInitial;
                return element;
            });
            setService(doc);
            setModalPeymentVisible(true);

        } catch (error) {
            if (error.response && error.response.status === 400)
                notification.warning({ message: 'Registro pago', description: error.response.data });
        } finally {
            setLoadingPayment(false);
        }
    };

    const handleModalCancelPeyment = () => {
        setModalPeymentVisible(false);
    };

    const handleModalOkPeyment = (accountingId, efe, camb) => {
        setAccountingId(accountingId);
        setEfectivo(efe);
        setCambio(camb);
        getDataTable(customer);
        setTimeout(() => {
            ticketPrint();
        }, 3000);
    };

    const getContracts = async (clientId) => {
        const { data } = await getContract(clientId, config.statusContract.activo);
        setDataContracts(data);
    }

    const getDataTable = async (e) => {
        setSelectedRowKeys([]);
        setDisBonusButtons(true);
        setDisPeyButtons(true);
        setTableLoading(true);
        try {
            const { data } = await getMovements(e);
            const newData = data.map((obj) => (
                {
                    'id': `${obj.accountingId}${obj.accountingDetailId}`,
                    ...obj
                }
            ));
            setDataTable(newData);
            setTempDataTable(newData);

        } finally {
            setTableLoading(false);
        }
    }

    const handleChange = async (e) => {
        setCustomer(e);
        setDataContracts([]);
        getContracts(e);
        await getDataTable(e);
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setDisBonusButtons(true);
        setDisPeyButtons(true);
        const debitCredit = selectedRows.filter(d => d.debitCreditIndicator === 'D' && d.lockIndicator !== 'S');
        const selected = debitCredit.map(s => s.id);
        if (auth.isInRoles('PAYMENT_BONUS') && selected.length === 1)
            setDisBonusButtons(false);
        if (auth.isInRoles('PAYMENT_REGISTER') && selected.length > 0)
            setDisPeyButtons(false);
        setSelectedRowKeys(selected);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const showPopconfirm = (msj, op) => {
        if (!customer)
            return notification.info({ message: msj, description: 'Selecciona un cliente.', });
        if (op === "AF")
            return setOpen(true);
        setOpenNC(true);
    };

    const columns = [
        {
            title: 'No. Documento', dataIndex: 'accountingId', render: (value, record) => record.lockIndicator !== 'S' ? value : <Popover content='Bloqueo por suspensión' title="Bloqueo">
                <Button danger icon={<BiBlock />}></Button>
            </Popover>
        },
        { title: 'Fecha documento', dataIndex: 'creationDate', render: (value) => moment(value).format(config.dateFormat) },
        { title: 'Subtotal', dataIndex: 'price', render: (value) => new Intl.NumberFormat(config.locale, { style: 'currency', currency: config.currency }).format(value) },
        { title: 'Descripción', dataIndex: 'description' },
        { title: 'Texto', dataIndex: 'text' },
        { title: 'No. servicio', dataIndex: 'contractId' },
        { title: 'Descuento', dataIndex: 'discount', render: (value) => new Intl.NumberFormat(config.locale, { style: 'currency', currency: config.currency }).format(value) },
        {
            title: 'Total', dataIndex: 'total', render: (value, record) =>
                new Intl.NumberFormat(config.locale, { style: 'currency', currency: config.currency }).format(record.debitCreditIndicator === 'C' ? value * -1 : value)
        },
        { title: 'Referencia', dataIndex: 'periodicity' },
        { title: 'Doc. Referencia', dataIndex: 'parentId' },
        { title: 'Domicilio', dataIndex: 'address' }
    ];

    const ticketPrint = () => {
        const printContents = document.getElementById('imprimible_div').innerHTML;
        const ventimp = window.open(' ', 'popimpr');
        ventimp.document.write("<html><head><title>Ticket</title><style>.imprimible{width:180px;display: none;max-width:180px;font-size:11px !important;font-family:'Times New Roman';color:black !important;}.txt-end{text-align:end;}.foo-ticket{margin-top:6em;}td,th,tr,table{border-collapse:collapse;font-size: 11px;}td.producto,th.producto{width:130px;max-width:130px;}td.precio,th.precio{width:50px;max-width:50px;word-break:break-all;}.centrado{text-align:center;align-content:center;}.t-info>p{margin-bottom:0 !important;}#contenedor{display:flex;flex-direction:row;flex-wrap:wrap;}#contenedor>div{width:50%;height:17.28px;}@media all{.page-break{display:none;}}@media print{.main-container{padding:0px !important;}.page-break{display:block;page-break-before: always;}.imprimible{display:block !important;}}</style></head><body>");
        ventimp.document.write(printContents);
        ventimp.document.write('</body></html>');
        ventimp.document.close();
        setTimeout(() => {
            ventimp.focus();
            ventimp.print();
            ventimp.close();
        }, 2500);
    }

    return <React.Fragment>
        <div className='noImprimible'>
            <div className='main-container'>
                <PaymentBonusModal visible={modalBonusVisible}
                    service={serviceBonus} onOk={handleModalOkBonus}
                    onCancel={handleModalCancelBonus}
                    salesOfficeId={salesOffice.id}
                />
                <PaymentModal visible={modalPeymentVisible}
                    service={service} onOk={handleModalOkPeyment}
                    onCancel={handleModalCancelPeyment}
                    salesOfficeId={salesOffice.id}
                />
                <PaymentContractsModal visible={modalContractsVisible}
                    contracts={dataContracts} onOk={handleModalOkContracts}
                    onCancel={handleModalCancelContracts}
                />
                <NotaCargoModal visible={modalNotaCargoVisible}
                    contract={contract} onOk={handleModalOkNotaCargo}
                    onCancel={handleModalCancelBonus}
                    salesOfficeId={salesOffice.id}
                />
                <SalesOfficeModal visible={modalSalesOfficeVisible}
                    salesOffice={dataSalesOffice} onOk={handleModalOkSalesOffice}
                />
                <Row>
                    <Col span={24}>
                        <h1 className="title">Pagos</h1>
                        <hr className="hr-title" />
                    </Col>
                </Row>
                <Row className="detail-container">
                    <Col span={24}>
                        <h4 className="div-right color-p"><FaBuilding />&nbsp;{salesOffice.name}
                            &nbsp; <Button type="primary" className={'collapse' + (btnEditOfficeVisible ? 'in' : '')}
                                shape="circle" icon={<FaPencilAlt />} onClick={() => setModalSalesOfficeVisible(true)} /></h4>
                    </Col>
                    <Col span={24}>
                        <Select
                            onChange={handleChange}
                            loading={selectLoading}
                            showSearch
                            disabled={disSelect}
                            style={{ width: 400 }}
                            placeholder="Buscar cliente:"
                            optionFilterProp="children"
                            filterOption={(input, option) => (normalized(option?.children) ?? '').includes(normalized(input))}
                            filterSort={(optionA, optionB) =>
                                (optionA?.completeName ?? '').toLowerCase().localeCompare((optionB?.completeName ?? '').toLowerCase())
                            }
                        >
                            {dataCustomers.map(function (obj) {
                                return <Option key={obj.id} value={obj.id}>{obj.completeName}</Option>
                            })}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <div className="div-right">
                            <Popconfirm
                                title="¿Quieres realizar una nota de cargo?"
                                open={openNC}
                                onConfirm={() => handleSetContract("Notas de cargo", "NC")}
                                okButtonProps={{
                                    loading: facturaLoading,
                                }}
                                onCancel={() => handleCancelPopconfirm("NC")}
                                cancelText="No"
                                okText="Si"
                            >
                                <Button type="primary" icon={<BiNotepad />} shape="round" onClick={() => showPopconfirm("Notas de cargo", "NC")}
                                    disabled={!auth.isInRoles('PAYMENT_CHARGE')}
                                >
                                    &nbsp; Notas de cargo
                                </Button>
                            </Popconfirm>
                            &nbsp;
                            <Button type="primary" icon={<FaHandHoldingUsd />} loading={loadingPayment} shape="round" onClick={handleClickNewPeyment} disabled={disPeyButtons}>
                                &nbsp; Registrar pago
                            </Button>
                            &nbsp;
                            <Popconfirm
                                title="¿Quieres adelantar factura?"
                                open={open}
                                onConfirm={() => handleSetContract("Adelantar factura", "AF")}
                                okButtonProps={{
                                    loading: facturaLoading,
                                }}
                                onCancel={() => handleCancelPopconfirm("AF")}
                                cancelText="No"
                                okText="Si"
                            >
                                <Button type="primary" loading={facturaLoading} icon={<FaFileInvoiceDollar />} shape="round" onClick={() => showPopconfirm("Adelantar factura", "AF")}
                                    disabled={!auth.isInRoles('PAYMENT_ADVANCE')}>
                                    &nbsp; Adelantar factura
                                </Button>
                            </Popconfirm>

                            &nbsp;
                            <Button type="primary" icon={<FaMoneyBill />} shape="round" onClick={handleClickNewBonus} disabled={disBonusButtons}>
                                &nbsp; Bonificar
                            </Button>
                        </div>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table rowKey="id" rowSelection={rowSelection} dataSource={dataTable} columns={columns} loading={tableLoading} pagination={{ pageSize: 15, hideOnSinglePage: true }} />
                    </Col>
                </Row>
            </div>
        </div>
        <div id='imprimible_div' >
            <div className='imprimible' >
                <Ticket accountingId={accountingId} efectivo={efectivo} cambio={cambio} />
            </div>
        </div>
    </React.Fragment>
}

export default Payment;