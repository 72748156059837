import { getCurrentUser } from "../services/authService";
import useSalesOffices from "./useSalesOffices";

const useMySalesOffices = () => {
    const { data, error, isLoading } = useSalesOffices();

    const user = getCurrentUser();
    const claimName = 'sales_office';
    const userSalesOffices = typeof user[claimName] === 'string' 
        ? [user[claimName]]
        : user[claimName];

    return { 
        data: data ? data.filter(d => userSalesOffices.some(uso => uso === d.id)) : [],
        error,
        isLoading 
    };
};

export default useMySalesOffices;