import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteSuspension } from "../services/contractService";

const useDeleteSuspension = ({ onError }) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ contractId, code }) => 
            // Fixed positionId to 1
            deleteSuspension(contractId, 1, code).then(res => res.data),
        onMutate: ({ contractId, code }) => {
            const prevData = queryClient.getQueryData(['suspensions', contractId]);
            // Update react query cache data from suspensions of that contract
            // before succes api call
            queryClient.setQueryData(
                ['suspensions', contractId],
                (suspensions) => suspensions.filter(s => s.code !== code));

            return { prevData };
        },
        onError: (_, { contractId }, context) => {
            // Rollback
            queryClient.setQueryData(['suspensions', contractId], () => context.prevData);
            onError();
        }
    });
}

export default useDeleteSuspension;