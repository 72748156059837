import notFound from '../../assets/images/not-found.png';

import { Row, Col } from 'antd';
import React from 'react';

export default function NotFound() {
	return (
		<Row justify='center' className=''>
			<Col xs={24} md={12}>
				<div className='hero-text'>
					<h4>404</h4>
					<h1>Página no</h1>
					<h1>encontrada...</h1>
					<div>
						<img className='img-notfound' alt='not-found' src={notFound} />
					</div>
				</div>
			</Col>
		</Row>
	);
}
