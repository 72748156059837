import { Table, Button, Row, Col, Switch, Tooltip, notification, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { FaPlusCircle, FaPencilAlt, FaCoins } from 'react-icons/fa';
import { getActiveCategories } from '../../services/CategoriesService';
import { getAllCostCategory } from '../../services/CostCategoryService';
import ProductModalEdit from './modalEdit';
import ProductModalNew from './modalNew';
import ProductModalStorage from './modalStorage';
import { getAllPlants } from '../../services/PlantsService';
import { getProducts, updateProduct } from '../../services/ProductsService';
import { normalized } from '../../utils/string';


export default function Products() {
	const [dataProducts, setDataProducts] = useState([]);
	const [modalVisibleNew, setModalVisibleNew] = useState(false);
	const [modalVisibleEdit, setModalVisibleEdit] = useState(false);
	const [modalVisibleStorage, setModalVisibleStorage] = useState(false);
	const [product, setProduct] = useState(null);
	const [plant, setPlant] = useState('');
	const [tableProductsLoading, setTableProductsLoading] = useState(false);
	const [costCategory, setCostCategory] = useState('');
	const [filterCategory, setfilterCategory] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [tempProducts, setTempProducts] = useState([]);

	useEffect(() => {
		async function process() {
			const { data } = await getActiveCategories();
			const filter = [];
			data.map(function (obj) {
				const item = {
					text: obj.name,
					value: obj.name,
				};
				filter.push(item);
				return true;
			});
			setfilterCategory(filter);
		}
		async function costCategory() {
			const { data } = await getAllCostCategory();
			setCostCategory(data[0]);
		}
		async function plantsStorage() {
			const { data } = await getAllPlants();
			setPlant(data[0]);
		}
		async function products() {
			setTableProductsLoading(true);
			const { data } = await getProducts();
			setDataProducts(data);
			setTempProducts(data);
			setTableProductsLoading(false);
		}
		products();
		process();
		costCategory();
		plantsStorage();
	}, []);

	const handleClickModalNew = () => {
		setProduct(null);
		setModalVisibleNew(true);
	};

	const handleClickModalEdit = record => {
		setProduct(record);
		setModalVisibleEdit(true);
	};

	const handleClickModalStorage = record => {
		setProduct(record);
		setModalVisibleStorage(true);
	};

	const onChangeActive = (record, checked) => {
		const request = {
			name: record.name,
			categoryId: record.category.id,
			description: record.description,
			active: checked,
			baseUnitId: record.baseUnit.id,
		};

		async function updateTheProduct() {
			try {
				await updateProduct(record.id, request);
				const dataProd = [...dataProducts];
				dataProd.find(x => x.id === record.id).active = checked;
				setDataProducts(dataProd);
				setTempProducts(dataProd);
				notification.success({ message: 'Producto', description: 'Se actualizó el producto.', });

			} catch (error) {
				notification.error({ message: 'Producto', description: 'Error al actualizar el producto.', });

			}
		}
		updateTheProduct();
	};

	const handleOkModalNew = data => {

		const model = {
			id: data.id,
			name: data.name,
			description: data.description,
			active: true,
			baseUnit: {
				id: data.baseUnitId,
			},
			category: {
				id: data.categoryId,
				name: data.categoryName,
			},
		};
		const temp = [...tempProducts];
		temp.push(model);
		setDataProducts(temp);
		setTempProducts(temp);
		setModalVisibleNew(false);

	};

	const handleOkModalEdit = item => {
		const tempData = [...tempProducts];
		const indx = tempData.findIndex(n => n.id === item.id);
		tempData[indx] = { ...tempData[indx], ...item };
		setDataProducts(tempData);
		setTempProducts(tempData);
		setModalVisibleEdit(false);
	};

	const handleCancelModalNew = () => {
		setModalVisibleNew(false);
	};

	const handleCancelModalEdit = () => {
		setModalVisibleEdit(false);
	};

	const handleOkModalStorage = () => {
		setModalVisibleStorage(false);
	};

	const handleCancelModalStorage = () => {
		setModalVisibleStorage(false);
	};

	const handleSearchProduct = e => {
		const currValue = e.target.value;
		setSearchValue(currValue);
		const temp = [...tempProducts];
		const filteredData = temp.filter(
			entry =>
				normalized(entry.id).includes(normalized(currValue)) ||
				normalized(entry.name).includes(normalized(currValue)) ||
				normalized(entry.description).includes(normalized(currValue))
		);
		if (currValue !== '') {
			setDataProducts(filteredData);
		} else {
			setDataProducts(tempProducts);
		}
	};

	const columns = [
		{
			title: 'Información',
			render: record => (
				<>
					{record.id}
					<br />
					{record.name}
					<br />
					{record.category.name}
				</>
			),
			responsive: ['xs'],
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			responsive: ['sm'],
		},
		{
			title: 'Producto',
			dataIndex: 'name',
			responsive: ['sm'],
		},
		{
			title: 'Descripción',
			dataIndex: 'description',
			responsive: ['sm'],
		},
		{
			title: 'Categoría',
			dataIndex: 'category',
			render: value => value.name,
			filters: filterCategory,
			onFilter: (value, record) => record.category.name.indexOf(value) === 0,
			responsive: ['sm'],
		},
		{
			title: '',
			dataIndex: 'active',
			align: 'center',
			key: 'active',
			render: (value, record) => (
				<Switch
					checked={value}
					onChange={checked => onChangeActive(record, checked)}
				/>
			),
		},
		{
			title: '',
			align: 'center',
			render: (value, record) => (
				<>
					<Tooltip title='Editar'>
						<Button
							type='primary'
							shape='circle'
							onClick={() => handleClickModalEdit(record)}
							icon={<FaPencilAlt />}
						/>
					</Tooltip>
					<Tooltip title='Almacenes'>
						<Button
							type='primary'
							shape='circle'
							onClick={() => handleClickModalStorage(record)}
							icon={<FaCoins />}
						/>
					</Tooltip>
				</>
			),
		},
	];

	return (
		<div className='mainContainer'>
			<ProductModalNew
				visible={modalVisibleNew}
				onCancelNew={handleCancelModalNew}
				dataProducts={dataProducts}
				onOkNew={handleOkModalNew}
			/>

			<ProductModalEdit
				visible={modalVisibleEdit}
				onCancelEdit={handleCancelModalEdit}
				onOkEdit={handleOkModalEdit}
				product={product}
			/>

			<ProductModalStorage
				visible={modalVisibleStorage}
				onCancelStorage={handleCancelModalStorage}
				onOkStorage={handleOkModalStorage}
				plant={plant}
				product={product}
				costCategory={costCategory}
			/>

			<Row>
				<Col span={24}>
					<h1 className='title'>Productos</h1>
					<hr className='hr-title' />
				</Col>
			</Row>

			<Row className='detailContainer'>
				<Col span={24}>
					<div className='divRight'>
						<Button
							type='primary'
							icon={<FaPlusCircle />}
							onClick={handleClickModalNew}>
							&nbsp; Nuevo
						</Button>
					</div>
				</Col>
				<Col span={6} offset={18}>
					<Input
						placeholder='Buscar producto'
						value={searchValue}
						onChange={handleSearchProduct}
					/>
				</Col>
				<Col span={24}>
					<Table
						className='mrg-2'
						rowKey='id'
						dataSource={dataProducts}
						columns={columns}
						loading={tableProductsLoading}
					/>
				</Col>
			</Row>
		</div>
	);
}
