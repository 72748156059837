import { SearchOutlined } from '@ant-design/icons';
import { Modal, Tabs, Table, Input } from 'antd';
import React, { useState, useEffect } from 'react';

import { getProducts } from '../../services/ProductsService';
import { getAllSet } from '../../services/SetService';
import { normalized } from '../../utils/string';

export default function MovementsProductsModal({
	visible: open,
	onCancel,
	onProductsSelected,
}) {
	const { TabPane } = Tabs;
	const [sets, setSets] = useState([]);
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [setsFilter, setSetsFilter] = useState([]);
	const [productsFilter, setProductsFilter] = useState([]);

	useEffect(() => {
		async function process() {
			setLoading(true);

			// Sets
			const { data } = await getAllSet();
			setSets(data);
			setSetsFilter(data);

			// Products
			const response = await getProducts();
			const pds = response.data.map(p => ({
				...p,
				baseUnit: p.baseUnit.name,
				categoryId: p.category.id,
				category: p.category.name,
			}));
			setProducts(pds);
			setProductsFilter(pds);

			setLoading(false);
		}

		process();
	}, []);

	const productsCols = [
		{ dataIndex: 'id', title: 'ID' },
		{ dataIndex: 'name', title: 'Nombre' },
		{ dataIndex: 'baseUnit', title: 'Unidad Medida' },
		{ dataIndex: 'category', title: 'Categoría' },
	];

	const handlerSearchSet = e => {
		const value = e.target.value;
		if (!value) setSetsFilter(sets);

		const filter = sets.filter(p =>
			normalized(p.name).includes(normalized(value))
		);

		setSetsFilter(filter);
	};

	const handlerSearchProduct = e => {
		const value = e.target.value;
		if (!value) setProductsFilter(products);

		const filtered = products.filter(
			p =>
				normalized(p.name).includes(normalized(value)) ||
				normalized(p.id).includes(normalized(value))
		);

		setProductsFilter(filtered);
	};

	return (
		<Modal title='Productos' open={open} onCancel={onCancel} footer={null}>
			<Tabs defaultActiveKey='1'>
				<TabPane tab='Productos' key='1'>
					<Input
						style={{ marginBottom: '15px' }}
						placeholder='Buscar...'
						prefix={<SearchOutlined />}
						onChange={handlerSearchProduct}
					/>
					<Table
						rowKey='id'
						style={{ overflowX: 'auto' }}
						dataSource={productsFilter}
						columns={productsCols}
						onRow={record => ({
							onClick: event =>
								(!event.detail || event.detail === 1) && // Prevents double execution when double click
								onProductsSelected([{ ...record, quantity: 1 }]),
						})}
						loading={loading}
					/>
				</TabPane>
				<TabPane tab='Paquetes' key='2'>
					<Input
						style={{ marginBottom: '15px' }}
						placeholder='Buscar...'
						prefix={<SearchOutlined />}
						onChange={handlerSearchSet}
					/>
					<Table
						rowKey='id'
						style={{ overflowX: 'auto' }}
						dataSource={setsFilter}
						columns={[{ dataIndex: 'name', title: 'Nombre' }]}
						onRow={record => ({
							onClick: () => onProductsSelected(record.products),
						})}
						expandable={{
							expandedRowRender: record => (
								<Table
									rowKey='id'
									dataSource={record.products}
									pagination={false}
									columns={[
										{ dataIndex: 'quantity', title: 'Cantidad', width: 60 },
										{ dataIndex: 'name', title: 'Producto' },
									]}
								/>
							),
						}}
						loading={loading}
					/>
				</TabPane>
			</Tabs>
		</Modal>
	);
}
