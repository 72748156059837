import { getHistory } from '../services/contractService';
import { useQuery } from "@tanstack/react-query";

export function useStatusHistogram(contractId) {
    return useQuery({
        queryKey: ['statusHistogram', contractId],
        queryFn: () => contractId ? getHistory(contractId).then(res => res.data) : null,
        staleTime: 24 * 60 * 60 * 100 // 24hrs
    })
}
