import http from './httpService';

export function getAccMov(request) {
	return http.post(`/AccountingMovement`, request);
}

export function addNotaCargo(request) {
	return http.post(`/AccountingMovement/Add`, request);
}

export default { getAccMov, addNotaCargo };
