import React, { useState, useEffect } from 'react';
import { Modal, Input, Row, Col, notification, Form } from 'antd';
import { updateProduct } from '../../services/ProductsService';

export default function ProductModalEdit(props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (props.product)
			form.setFieldsValue({
				name: props.product.name,
				description: props.product.description
			});
	}, [props.product]);

	const handleOk = () => {
		form.validateFields().then(async values => {
			setLoading(true);
			const requestProduct = {
				categoryId: props.product.category.id,
				name: values.name,
				description: values.description,
				active: props.product.active,
				baseUnitId: props.product.baseUnit.id,
			};
			try {
				const { data } = await updateProduct(props.product.id, requestProduct);
				notification.success({ message: 'Producto', description: 'Se actualizó el producto.', });
				props.onOkEdit(data);
			} catch (error) {
				notification.error({ message: 'Producto', description: 'Error al actualizar el producto.', });

			} finally {
				setLoading(false);
			}
		}).catch(() => { });
	};

	return (
		<>
			<Modal
				title='Editar producto'
				open={props.visible}
				confirmLoading={loading}
				onOk={handleOk}
				okText="Guardar"
				onCancel={() => props.onCancelEdit()}
				cancelText="Cancelar">
				<Form form={form} layout="vertical" className='all-width'>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item name="name" label="Nombre"
								rules={[{ required: true, message: 'El Nombre no debe ser vacío' }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="description" label="Descripción"
								rules={[{ required: true, message: 'La Descripción no debe ser vacía' }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
}
