import {
	Modal,
	Row,
	Col,
	notification,
	Button,
	Select,
	Tooltip,
	Table,
	Switch,
} from 'antd';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';

export default function ModalCategory(props) {
	const { Option } = Select;
	const [catVisible, setCatVisible] = useState(false);
	const [rangoCatVisible, setRangoCatVisible] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(false);
	const [dataTableCat, setDataTableCat] = useState([]);
	const [indicador, setIndicador] = useState(true);
	const [cat, setCat] = useState('');
	const [rangoCat1, setRangoCat1] = useState('');
	const [rangoCat2, setRangoCat2] = useState('');
	const [operator, setOperator] = useState('');

	const handleOk = () => {
		props.handleOkModalCat(dataTableCat);
	};

	const handleCancel = () => {
		props.handleCancelModalCat();
	};

	const handleChangeOperation = value => {
		setOperator(value);
		if (value === 'BT') {
			setCatVisible(false);
			setRangoCatVisible(true);
			setButtonVisible(true);
		} else {
			setCatVisible(true);
			setRangoCatVisible(false);
			setButtonVisible(true);
		}
	};

	const handleChangeCat = value => {
		setCat(value);
	};

	const handleChangeRangeCat1 = value => {
		setRangoCat1(value);
	};

	const handleChangeRangeCat2 = value => {
		setRangoCat2(value);
	};

	const handleAgregar = () => {
		if (catVisible) {
			if (cat === '') {
				notification.info({ message: 'Categorías', description: 'Selecciona una categoría para agregarla.', });

			} else {
				const dataF = [...dataTableCat];
				dataF.push({
					key: dataF.length + 1,
					option: operator,
					sign: indicador ? 'I' : 'E',
					low: cat,
					high: null,
				});
				setDataTableCat(dataF);
			}
		}
		if (rangoCatVisible) {
			if (rangoCat1 === '' && rangoCat2 === '') {
				notification.info({ message: 'Categorías', description: 'Selecciona un rango de categorias para agregarlas.', });

			} else {
				const dataF = [...dataTableCat];
				dataF.push({
					key: dataF.length + 1,
					option: operator,
					sign: indicador ? 'I' : 'E',
					low: rangoCat1 > rangoCat2 ? rangoCat2 : rangoCat1,
					high: rangoCat1 > rangoCat2 ? rangoCat1 : rangoCat2,
				});
				setDataTableCat(dataF);
			}
		}
	};

	const handleClickDelete = record => {
		const dataF = [...dataTableCat];
		const data = dataF.filter(x => x.key !== record.key);
		setDataTableCat(data);
	};

	const onChangeIndicator = checked => {
		setIndicador(checked);
	};

	const columns = [
		{
			title: 'Indicador',
			dataIndex: 'sign',
		},
		{
			title: 'Operación',
			dataIndex: 'option',
		},
		{
			title: 'Categoría',
			render: (value, record) =>
				record.high == null ? record.low : record.low + ' - ' + record.high,
		},
		{
			title: '',
			align: 'center',
			render: (value, record) => (
				<React.Fragment>
					<Tooltip title='Quitar'>
						<Button
							type='primary'
							shape='circle'
							onClick={() => handleClickDelete(record)}
							icon={<IoMdCloseCircle />}
						/>
					</Tooltip>
				</React.Fragment>
			),
		},
	];

	return (
		<>
			<Modal
				title='Categorias'
				open={props.modalVisibleCat}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cerrar
					</Button>,
					<Button key='submit' type='primary' onClick={handleOk}>
						Tomar
					</Button>,
				]}>
				<Row>
					<Col span={24}>
						<p>
							<FaInfoCircle /> Indicadores : I = Incluir, E = Excluir.
						</p>
					</Col>
					<Col span={4}>
						<Switch
							onChange={onChangeIndicator}
							checkedChildren='I'
							unCheckedChildren='E'
							defaultChecked
						/>
					</Col>
					<Col span={20}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht'
							onChange={handleChangeOperation}
							placeholder='Selecciona la operación...'>
							<Option value='EQ'>EQ - Igual a</Option>
							<Option value='BT'>BT - Entre </Option>
							<Option value='NE'>NE - No igual a</Option>
							<Option value='GE'>GE - Mayor igual a</Option>
							<Option value='LE'>LE - Menor igual a</Option>
							<Option value='GT'>GT - Mayor que</Option>
							<Option value='LT'>LT - Menor que</Option>
						</Select>
					</Col>
					<Col span={12} className={'collapse' + (catVisible ? 'in' : '')}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht mrg-2'
							onChange={handleChangeCat}
							placeholder='Selecciona una categoría...'>
							{props.dataCategories.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.id + ' - ' + obj.name}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col span={11} className={'collapse' + (rangoCatVisible ? 'in' : '')}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht mrg-2'
							onChange={handleChangeRangeCat1}
							placeholder='Selecciona una categoría...'>
							{props.dataCategories.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.id + ' - ' + obj.name}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col
						span={2}
						className={'collapse' + (rangoCatVisible ? 'in' : '')}></Col>
					<Col span={11} className={'collapse' + (rangoCatVisible ? 'in' : '')}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht mrg-2'
							onChange={handleChangeRangeCat2}
							placeholder='Selecciona una categoría...'>
							{props.dataCategories.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.id + ' - ' + obj.name}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col span={2} className={'collapse' + (catVisible ? 'in' : '')}></Col>
					<Col
						span={rangoCatVisible ? 24 : 10}
						className={'collapse' + (buttonVisible ? 'in' : '')}>
						<Button
							className="mrg-2"
							block
							type='primary'
							icon={<IoMdCheckmarkCircle />}
							onClick={handleAgregar}>
							&nbsp; Agregar
						</Button>
					</Col>
					<Col span={24} className="mrg-2">
						<Table rowKey='key' dataSource={dataTableCat} columns={columns} />
					</Col>
				</Row>
			</Modal>
		</>
	);
}
