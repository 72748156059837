import http from './httpService';

export function getStats(statusId) {
	return http.get(`Contracts/GetCountByStatus/?contractStatusId=${statusId}`);
}

export function getContracts(statusId, take, skip) {
	return http.get(
		`Contracts/GetAllByStatus?contractStatusId=${statusId}&take=${take}&skip=${skip}`
	);
}

export function getContract(contractId) {
	return http.get(`Contracts/${contractId}`);
}

export function createContract(contract) {
	return http.post(`Contracts`, contract);
}

export function cancelContract(contractId, comments) {
	return http.patch(`Contracts/Cancel/${contractId}`, {
		comments,
	});
}

export function reActivateContract(contractId, salesOfficeId) {
	return http.patch(`Contracts/ReActivate/${contractId}`, { salesOfficeId });
}

export function suspendContract(newSuspension) {
	return http.post(`Contracts/${newSuspension.contractId}/Suspensions`, {
		...newSuspension,
		contractStatusId: "SUS."
	});
}

export function update(contractId, contract) {
	return http.patch(`Contracts/Update/${contractId}`, contract);
}

export function getHistory(contractId) {
	return http.get(`Contracts/${contractId}/StatusHistory`);
}

export function getSuspensions(contractId, positionId){
	return http.get(`Contracts/${contractId}/Position/${positionId}/LayOffs`);
}

export function deleteSuspension(contractId, positionId, code) {
	return http.delete(`Contracts/${contractId}/Position/${positionId}/LayOff/${code}`)
}

export function checkFutureLayOffs(contracts) {
	return http.post(`Contracts/CheckFutureLayOffs`, contracts);
}


const contractService = {
	getStats,
	getContracts,
	getContract,
	createContract,
	cancelContract,
	reActivateContract,
	suspendContract,
	update,
	getHistory,
	getSuspensions,
	deleteSuspension,
	checkFutureLayOffs
};

export default contractService;