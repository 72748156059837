import { Modal, Input, Row, Col, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { addCategory, updateCategory } from '../../services/CategoriesService';

export default function CategoryModal(props) {
    const [name, setName] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.category)
            setName(props.category.name);
    }, [props.category]);

    const handleOk = async () => {
        if (name === '')
            return notification.warning({ message: 'Categoría', description: 'El nombre de la categoría no debe de ser vacio.' });

        setLoading(true);

        if (props.category) {
            const requestCategory = {
                id: props.category.id,
                name,
                active: props.category.active,
            };
            try {
                const { data } = await updateCategory(props.category.id, requestCategory);
                notification.success({ message: 'Categoría', description: 'Se actualizó la descripción de la categoría.' });
                props.onOk(data);
                setName('');
            } catch (error) {
                notification.error({ message: 'Categoría', description: 'Error al actualizar la descripción de la categoría.' });
            } finally {
                setLoading(false);
            }
        } else {
            const request = {
                name,
                active: true,
            };
            try {
                const { data } = await addCategory(request);
                notification.success({ message: 'Categoría', description: 'Se creó la categoría.' });
                props.onOk(data);
                setName('');
            } catch (error) {
                notification.error({ message: 'Categoría', description: 'Error al crear la categoría.' });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCancel = () => {
        props.onCancel();
        setName('');
    };

    return (
        <>
            <Modal
                title={`${props.category ? "Editar" : "Nuevo"} Categoría`}
                open={props.visible}
                confirmLoading={loading}
                onOk={handleOk}
                okText="Guardar"
                onCancel={handleCancel}
                cancelText="Cancelar">
                <Row>
                    <Col span={24}>
                        <Input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder='Nombre'
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
