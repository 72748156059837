import { Modal, Input, Row, Col, Select, Form, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { LETTERS_NUMBERS_PATTERN } from '../../utils/regex';
import { getAllUnitsOfMeasure } from '../../services/UnitsOfMeasureService';
import { getActiveCategories } from '../../services/CategoriesService';
import { addProduct } from '../../services/ProductsService';

const { Option } = Select;
const notCategory = [1,3]

export default function ProductModalNew(props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [dataUnits, setDataUnits] = useState([]);
	const [dataCategories, setDataCategories] = useState([]);

	useEffect(() => {
		async function unitsOfM() {
			const { data } = await getAllUnitsOfMeasure();
			setDataUnits(data);
		}
		async function categorys() {
			const { data } = await getActiveCategories();
			const filtro = data.filter(c => !notCategory.includes(c.id));
			setDataCategories(filtro);
		}
		categorys();
		unitsOfM();
	}, []);

	const handleOk = () => {
		form.validateFields().then(async values => {
			setLoading(true);
			const catName = dataCategories.find(c => c.id === values.category).name;
			const request = {
				id: values.id,
				name: values.name,
				categoryId: values.category,
				description: values.description,
				active: true,
				baseUnitId: values.baseUnit,
			};

			try {
				const { data } = await addProduct(request);
				data.categoryName = catName;
				notification.success({ message: 'Producto', description: 'Se creó el producto.', });
				props.onOkNew(data);
				form.resetFields();
			} catch (error) {
				notification.error({ message: 'Producto', description: 'Error al crear el producto.', });

			} finally {
				setLoading(false);
			}
		}).catch(() => { });
	};

	const handleCancel = () => {
		form.resetFields();
		props.onCancelNew();
	};

	const idsValidator = (value) => {
		const item = props.dataProducts.find(
			element => element.id.toUpperCase() === value.toUpperCase()
		);
		if (item) return true;
		return false;
	};

	const handleInputTrim = ({ target }) => form.setFieldValue(target.id, target.value.trim());

	return <Modal
		title="Nuevo Producto"
		open={props.visible}
		confirmLoading={loading}
		onOk={handleOk}
		okText="Guardar"
		onCancel={handleCancel}
		cancelText="Cancelar">
		<Form form={form} layout="vertical" className='all-width'>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="id" label="ID"
						rules={[{ pattern: LETTERS_NUMBERS_PATTERN, message: 'ID no valido', required: true },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (idsValidator(value))
									return Promise.reject(new Error("El ID ya existe"));
								return Promise.resolve();
							}
						})]}>
						<Input onBlur={handleInputTrim} />
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item name="name" label="Nombre"
						rules={[{ required: true, message: 'El Nombre no debe ser vacío' }]}>
						<Input onBlur={handleInputTrim} />
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item name="description" label="Descripción"
						rules={[{ required: true, message: 'La Descripción no debe ser vacía' }]}>
						<Input onBlur={handleInputTrim} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="category" label="Categoría"
						rules={[{ required: true, message: 'Selecciona una Categoría' }]}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht'>
							{dataCategories.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item name="baseUnit" label="Unidad de medida"
						rules={[{ required: true, message: 'Selecciona una Unidad de medida' }]}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht'>
							{dataUnits.map(function (obj) {
								return (
									<Option key={obj.unit} value={obj.unit}>
										{obj.description}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	</Modal>;
}
