import http from './httpService';

export function getAllPlants() {
	return http.get(`/Plants`);
}

export function addStorage(id, request) {
	return http.post(`/Plants/${id}/StorageLocation`, request);
}

export function getAllStorage(id) {
	return http.get(`/Plants/${id}/StorageLocation`);
}

export default { getAllPlants, addStorage, getAllStorage };
