import './index.css';
import pos from '../../assets/images/pos.png';
import itfortal from '../../assets/images/ITFORTAL_R.png';

import { Row, Col } from 'antd';
import React from 'react';

export default function Initial() {
    return (
        <React.Fragment>
            <Row justify='center'>
                <Col xs={24} md={12}>
                    <div className='initial-container-logo'>
                        <img className='initial-logo' alt='pos' src={pos} />
                    </div>

                </Col>
            </Row>
            <div className="initial-container-powered">
                <p className='initial-powered'>
                    <strong> Powered by &nbsp;</strong>
                    <a title='ITFORTAL' href='https://www.itfortal.com/'>
                        <img className='initial-img-powered' alt='powered' src={itfortal} />
                    </a>
                </p>
            </div>
        </React.Fragment>
    );
}