import React from 'react';
import { Col, Row } from 'antd';

const Container = ({ children, title }) => <div className="main-container">
    <Row>
        <Col span={24}>
            <h1 className="title">{title}</h1>
            <hr className="hr-title" />
        </Col>
    </Row>
    <Row className="detail-container">
        <Col span={24}>
            {children}
        </Col>
    </Row>
</div>

export default Container;