import http from './httpService';

export function getAllMovements(request) {
	return http.post(`/LogisticMovement/GetAll`, request);
}

export function getMovement(id) {
	return http.get(`/LogisticMovement/${id}`);
}

export function cancelMovement(id) {
	return http.post(`/LogisticMovement/${id}/Cancel`);
}

export function createMovement(movement) {
	return http.post(`/LogisticMovement`, movement);
}

export default {
	getMovement,
	cancelMovement,
	createMovement,
	getAllMovements,
};
