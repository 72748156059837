/**
 * Recursively nullify empty strings within an object.
 *
 * @param {Object} obj - The input object to process.
 */
export function nullifyEmptyStrings(obj) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                nullifyEmptyStrings(obj[key]); // Recursively traverse nested objects
            } else if (typeof obj[key] === 'string' && obj[key].trim() === '') {
                obj[key] = null;
            }
        }
    }
}

const objectUtils = {
    nullifyEmptyStrings
};

export default objectUtils;