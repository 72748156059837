import http from './httpService';

export function getAcc(request) {
	return http.post(`/Account`, request);
}

export function cancelAccounting(accountingId) {
	return http.post(
		`/AccountingMovement/CancelCompensation?accountingId=${accountingId}`
	);
}

export default { getAcc };
