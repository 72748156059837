import logo from '../../assets/images/ITFORTAL_R.png';

import { Layout as LayoutAntd } from 'antd';
import React from 'react';
import { Outlet } from 'react-router';

import Sidebar from './sidebar';

export default function Layout() {
	const { Content } = LayoutAntd;
	return (
		<div className='layout'>
			<Sidebar />
			<Content className='main-container'>
				<Outlet />
			</Content>
			<footer className='content-footer noImprimible'>
				<p className='container-powered'>
					<strong> Powered by &nbsp;</strong>
					<a title='ITFORTAL' href='https://www.itfortal.com/'>
						<img className='img-powered' alt='powered' src={logo} />
					</a>
				</p>
			</footer>
		</div>
	);
}
