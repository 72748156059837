import { Modal, Row, Button, Table, Col } from 'antd';
import React, { useState } from 'react';

export default function MovementProductsModal(props) {
	const [selectedRowKeysProduct, setSelectedRowKeysProduct] = useState([]);

	const handleOk = () => {
		props.handleOkModalProducts(selectedRowKeysProduct);
	};

	const handleCancel = () => {
		props.handleCancelModalProducts();
	};

	const onSelectChangeProduct = selectedRowKeys => {
		setSelectedRowKeysProduct(selectedRowKeys);
	};

	const rowSelectionProduct = {
		selectedRowKeys: selectedRowKeysProduct,
		onChange: onSelectChangeProduct,
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			responsive: ['sm'],
		},
		{
			title: 'Producto',
			dataIndex: 'name',
			responsive: ['sm'],
		},
		{
			title: 'Categoría',
			dataIndex: 'category',
			render: value => value.name,
			filters: props.filterCategory,
			onFilter: (value, record) => record.category.name.indexOf(value) === 0,
			responsive: ['sm'],
		},
	];

	return (
		<>
			<Modal
				title='Productos'
				open={props.modalVisibleProducts}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cerrar
					</Button>,
					<Button key='submit' type='primary' onClick={handleOk}>
						Tomar
					</Button>,
				]}>
				<Row>
					<Col span={24}>
						<Table
							rowKey='id'
							dataSource={props.dataProducts}
							columns={columns}
							rowSelection={rowSelectionProduct}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
}
