import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Modal, notification, Select, Skeleton, Space } from 'antd';
import React, { useState } from 'react';
import { reActivateContract } from '../../services/contractService';
import useMySalesOffices from './../../hooks/useMySalesOffices';

const ReactivateModal = ({ contractId, open, onCancel, onOk }) => {
    const { data: salesOfices, error, isLoading } = useMySalesOffices();
    const [selectedSalesOffice, setSelectedSalesOffice] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        // If just one salesOffice in the list will pick that one
        const salesOffice = salesOfices.length === 1 
            ? salesOfices[0].id
            : selectedSalesOffice;

        setLoading(true);

        try {
            await reActivateContract(contractId, salesOffice);
            onOk();
        } catch(error) {
            notification.error({ message: 'Reactivar', description: 'No fue posible reactivar el contrato.' });
        } finally {
            setLoading(false);
        }
    }

    const disableBtn = () => salesOfices && salesOfices.length > 1 && !selectedSalesOffice;

    return <Modal
        title="Reactivar contrato"
        open={open}
        footer={null}
        onCancel={onCancel}
        centered>
        {isLoading 
        ? <Skeleton active />
        : <Space
            direction='vertical'
            align='center'
            size='middle'
            style={{ width: "100%" }}>
            {salesOfices && salesOfices.length > 1 && <Select
                placeholder="Oficina de Ventas"
                style={{ width: "250px" }}
                status={error ? "error" : ""}
                onChange={(value) => setSelectedSalesOffice(value)}
                options={salesOfices && salesOfices.map(({ id, name }) => ({ value: id, label: name }))} />}
            <Button
                onClick={handleClick}
                style={{ width: "250px"}}
                size='large'
                disabled={disableBtn()}
                loading={loading}
                icon={<CheckCircleTwoTone twoToneColor={'#16a34a'} />}>
                Reactivar
            </Button>
        </Space>}
    </Modal>;
};

export default ReactivateModal;