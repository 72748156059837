import http from './httpService';

const endPoint = "/Characteristic";

export function addResults(results, objectId) {
	return http.post(`${endPoint}/AddResults`, results, { headers: { objectId } });
}

export function getResults(objectId) {
	return http.get(`${endPoint}/GetResults`, { headers: { objectId } });
}

const characteristicsService = { 
	addResults,
	getResults
};

export default characteristicsService;