import {
	Modal,
	Row,
	Col,
	notification,
	Button,
	Select,
	DatePicker,
	Tooltip,
	Table,
	Switch,
} from 'antd';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';

import config from "../../config.json"

export default function ModalFecha(props) {
	const { Option } = Select;
	const { RangePicker } = DatePicker;
	const [fechaVisible, setFechaVisible] = useState(false);
	const [rangoFechaVisible, setRangoFechaVisible] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(false);
	const [dataTableFechas, setDataTableFechas] = useState([]);
	const [indicador, setIndicador] = useState(true);
	const [fecha, setFecha] = useState('');
	const [rangoFecha, setRangoFecha] = useState([]);
	const [operator, setOperator] = useState('');

	const handleOk = () => {
		props.handleOkModalFecha(dataTableFechas);
	};

	const handleCancel = () => {
		props.handleCancelModalFecha();
	};

	const handleChangeOperation = value => {
		setOperator(value);
		if (value === 'BT') {
			setFechaVisible(false);
			setRangoFechaVisible(true);
			setButtonVisible(true);
		} else {
			setFechaVisible(true);
			setRangoFechaVisible(false);
			setButtonVisible(true);
		}
	};

	const handleChangeDate = (dates, dateString) => {
		setFecha(dateString);
	};

	const handleChangeRangeDate = (dates, dateString) => {
		setRangoFecha(dateString);
	};

	const handleAgregar = () => {
		if (fechaVisible) {
			if (fecha === '') {
				notification.info({ message: 'Fecha', description: 'Selecciona una fecha para agregarla.', });
			} else {
				const dataF = [...dataTableFechas];
				dataF.push({
					key: dataF.length + 1,
					option: operator,
					sign: indicador ? 'I' : 'E',
					low: fecha,
					high: null,
				});
				setDataTableFechas(dataF);
			}
		}
		if (rangoFechaVisible) {
			if (rangoFecha.length === 0) {
				notification.info({ message: 'Fechas', description: 'Selecciona un rango de fechas para agregarlas.', });

			} else {
				const dataF = [...dataTableFechas];
				dataF.push({
					key: dataF.length + 1,
					option: operator,
					sign: indicador ? 'I' : 'E',
					low: rangoFecha[0],
					high: rangoFecha[1],
				});
				setDataTableFechas(dataF);
			}
		}
	};

	const handleClickDelete = record => {
		const dataF = [...dataTableFechas];
		const data = dataF.filter(x => x.key !== record.key);
		setDataTableFechas(data);
	};

	const onChangeIndicator = checked => {
		setIndicador(checked);
	};

	const columns = [
		{
			title: 'Indicador',
			dataIndex: 'sign',
		},
		{
			title: 'Operación',
			dataIndex: 'option',
		},
		{
			title: 'Fecha',
			render: (value, record) =>
				record.high == null ? record.low : record.low + ' - ' + record.high,
		},
		{
			title: '',
			align: 'center',
			render: (value, record) => (
				<React.Fragment>
					<Tooltip title='Quitar'>
						<Button
							type='primary'
							shape='circle'
							onClick={() => handleClickDelete(record)}
							icon={<IoMdCloseCircle />}
						/>
					</Tooltip>
				</React.Fragment>
			),
		},
	];

	return (
		<>
			<Modal
				title='Fecha'
				open={props.modalVisibleFecha}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cerrar
					</Button>,
					<Button key='submit' type='primary' onClick={handleOk}>
						Tomar
					</Button>,
				]}>
				<Row>
					<Col span={24}>
						<p>
							<FaInfoCircle /> Indicadores : I = Incluir, E = Excluir.
						</p>
					</Col>
					<Col span={4}>
						<Switch
							onChange={onChangeIndicator}
							checkedChildren='I'
							unCheckedChildren='E'
							defaultChecked
						/>
					</Col>
					<Col span={20}>
						<Select
							style={{ width: '100%' }}
							className='cbx-widht'
							onChange={handleChangeOperation}
							placeholder='Selecciona la operación...'>
							<Option value='EQ'>EQ - Igual a</Option>
							<Option value='BT'>BT - Entre fechas</Option>
							<Option value='NE'>NE - No igual a</Option>
						</Select>
					</Col>
					<Col span={24}>
						<br />
					</Col>
					<Col span={12} className={'collapse' + (fechaVisible ? 'in' : '')}>
						<DatePicker
							format={config.dateFormat}
							onChange={handleChangeDate}
							locale={locale}
							style={{ width: '100%' }}
						/>
					</Col>
					<Col
						span={12}
						className={'collapse' + (rangoFechaVisible ? 'in' : '')}>
						<RangePicker
							onChange={handleChangeRangeDate}
							format={config.dateFormat}
							locale={locale}
							style={{ width: '100%' }}
						/>
					</Col>
					<Col span={24}><br /></Col>
					<Col span={10} className={'collapse' + (buttonVisible ? 'in' : '')}>
						<Button
							block
							type='primary'
							icon={<IoMdCheckmarkCircle />}
							onClick={handleAgregar}>
							&nbsp; Agregar
						</Button>
					</Col>
					<Col span={24}>
						<br />
					</Col>
					<Col span={24}>
						<Table
							rowKey='key'
							dataSource={dataTableFechas}
							columns={columns}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
}
