import http from './httpService';

const endPoint = "/Clients";

export function getClients() {
	return http.get(endPoint);
}

export function getCharacteristics(clientGroupId) {
	return http.get(`${endPoint}/Characteristics`, { params: { clientGroupId }});
}

const clientService = {
	getClients,
	getCharacteristics
}

export default clientService;