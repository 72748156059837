// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { useCallback, useContext, useState } from 'react';

import http from '../services/httpService';
import { login as loginService } from '../services/authService';
import Context from '../context/userContext';

export default function useAuth() {
	const {
		token,
		setToken,
		customerId,
		setCustomerId,
		organizationId,
		setOrganizationId,
		user,
		setUser,
		userRoles,
		setUserRoles,
	} = useContext(Context);
	const [loginLoading, setLoginLoading] = useState(false);
	const [loginError, setLoginError] = useState(null);
	http.setDefaultHeaders(customerId, organizationId);
	http.setAuthorization(token);

	const login = useCallback(
		async ({ username, password }) => {
			setLoginLoading(true);
			try {
				const { data } = await loginService(username, password);
				setToken(data.token);
				window.localStorage.setItem('token', data.token);
				const user = jwt_decode(data.token);
				const roles =
					user.role.constructor !== Array ? user.role.split('') : user.role;
				setUserRoles(roles);
				setUser(user);
			} catch (error) {
				const isLoginError = error.response && error.response.status === 400;
				if (isLoginError) {
					setLoginError(new Error('Usuario o contraseña incorrectos'));
					return;
				}

				setLoginError(new Error('Ocurrió un error al iniciar sesión'));
			} finally {
				setLoginLoading(false);
			}
		},
		[setToken]
	);

	const logout = useCallback(() => {
		setToken(null);
		setCustomerId(null);
		setOrganizationId(null);
		setUser(null);
		window.localStorage.removeItem('token');
		window.localStorage.removeItem('customerId');
		window.localStorage.removeItem('organizationId');
	}, [setToken]);

	const getUser = () => {
		const user = jwt_decode(token);
		setUser(user);
		return user;
	};

	return {
		customerId,
		getUser,
		isUserAuthenticated: Boolean(token),
		login,
		loginLoading,
		loginError,
		logout,
		organizationId,
		token,
		user,
		userRoles,
	};
}
