
export function groupBy(array, key){
    return array.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

const arrayUtils = {
    groupBy
}

export default arrayUtils;
