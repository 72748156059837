import React, { useState } from 'react'
import { Modal, Input, Row, Form, notification } from 'antd'
import { MONTO_PATTERN } from "../../utils/regex";
import { addNotaCargo } from '../../services/accountingMovementService';

const { TextArea } = Input;

function NotaCargoModal(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            try {
                const request = {
                    accountingTypeId: "DN",
                    clientId: props.contract.clientId,
                    contractId: props.contract.contractId,
                    text: values.text,
                    salesOfficeId: props.salesOfficeId,
                    accountingDetails: [
                        {
                            id: "1",
                            value: values.cargo,
                            accountId: "0007",
                            debitCreditIndicator: "D",
                            text: values.text,
                            productId: props.contract.productId,
                            productCategoryId: props.contract.categoryId,
                            NoEPD: true
                        },
                        {
                            id: "2",
                            value: values.cargo,
                            accountId: "0010",
                            debitCreditIndicator: "C",
                            text: values.text,
                            productId: props.contract.productId,
                            productCategoryId: props.contract.categoryId,
                            NoEPD: true
                        }
                    ]
                };
                await addNotaCargo(request);
                props.onOk();
                notification.success({ message: 'Nota de Cargo', description: 'Se aplicó la Nota de cargo.', });
                form.resetFields();
            } finally {
                setLoading(false);
            }
        });
    };

    const handleCancel = () => {
        props.onCancel();
    }

    return <Modal title="Nota de cargo"
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Row>
            <Form form={form} layout="vertical" className='all-width'>
                <Form.Item name="cargo" label="Monto"
                    rules={[
                        { required: true, message: 'Falta colocar el monto' },
                        { pattern: MONTO_PATTERN, message: 'No es un monto aceptado' },
                    ]}>
                    <Input className='txt-money' prefix={'$'} />
                </Form.Item>
                <Form.Item name="text" label="Texto"
                    rules={[
                        { required: true, message: 'Falta colocar un texto' },
                    ]}>
                    <TextArea rows={4} maxLength={128} />
                </Form.Item>
            </Form>
        </Row>
    </Modal>
}

export default NotaCargoModal;