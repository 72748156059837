import { Table, Drawer } from 'antd';
import moment from 'moment';
import React from 'react';
import config from '../../config.json';

const { dateTimeFormat } = config;

export default function StatusHistoryDrawer({ history, open, close }) {

	const data = history && history.map((status, index) => ({
		key: index,
		date: moment(status.creationDate).format(dateTimeFormat),
		status: status.contractStatusName,
		user: status.userName
	})).sort((left, right) =>
		new Date(right.date) - new Date(left.date)
	);

	const columns = [
		{
			title: 'Fecha',
			dataIndex: 'date',
		},
		{
			title: 'Estatus',
			dataIndex: 'status',
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
		},
	];

	return (
		<Drawer title="Historial de Estatus" placement="right" onClose={close}
			open={open}>
			<Table
				dataSource={data}
				columns={columns}
				pagination={false}
				size='small'
			/>
		</Drawer>

	);
}
