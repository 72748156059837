import { Form, Button, Input, notification } from 'antd';
import React, { useState } from 'react';

import { cancelContract } from '../../services/contractService';

export default function Cancel({ contractId, onOkCancelContract }) {
	const { TextArea } = Input;
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = async values => {
		try {
			setLoading(true);
			await cancelContract(contractId, values.cancelReason);

			onOkCancelContract();
		} catch (error) {
			if (error.response && error.response.status === 400)
				notification.warning({ message: 'Cancelar', description: error.response.data, duration: 7 });
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form layout='vertical' form={form} onFinish={handleFinish}>
			<Form.Item
				name='cancelReason'
				label='Ingresar motivo de cancelación'
				rules={[
					{
						required: true,
						message: 'Ingresa el motivo de la cancelación',
					},
				]}>
				<TextArea
					rows={4}
					placeholder='Cliente tenía una expectativa diferente del servicio'
				/>
			</Form.Item>
			<br />
			<Form.Item>
				<Button type='primary' htmlType='submit' size='large' loading={loading}>
					Guardar
				</Button>
			</Form.Item>
		</Form>
	);
}
