import React, { useState, useEffect } from 'react'
import { Modal, Input, Row, Form, notification } from 'antd'
import { MONTO_PATTERN } from "../../utils/regex";
import { createPayment } from '../../services/paymentService';
import config from '../../config.json';

function PaymentModal(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [btnDis, setBtnDis] = useState(false);

    useEffect(() => {
        if (!props.service) return;
        const initialValue = 0;
        const sumWithInitial = props.service.reduce(
            (accumulator, currentValue) => accumulator + (currentValue.total - currentValue.credit),
            initialValue
        );
        form.setFieldsValue({
            total: new Intl.NumberFormat(config.locale, { style: 'currency', currency: config.currency }).format(sumWithInitial)
        });
    }, [props.service, form]);

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            try {
                const arrayAccounting = props.service.reduce((accumulator, currentValue) => {
                    if (!accumulator.includes(currentValue.accountingId)) {
                        return [...accumulator, currentValue.accountingId];
                    }
                    return accumulator;
                }, []);
                const request = [];
                arrayAccounting.forEach(element => {
                    const docs = props.service.filter(s => s.accountingId === element);
                    const detailIds = docs.reduce(
                        (accumulator, currentValue) => [...accumulator, currentValue.accountingDetailId],
                        []
                    );
                    const item = {
                        'accountingId': element,
                        'accountingDetailId': detailIds
                    };
                    request.push(item);
                });
                const { data } = await createPayment(props.service[0].clientId, request, props.salesOfficeId);
                props.onOk(data, values.pago.toString().replace(',', ''), values.cambio.substring(1));
                notification.success({ message: 'Pago', description: 'Se aplicó el pago.' });
                setBtnDis(true);
            } catch (error) {
                if (error.response && error.response.status === 400)
                    notification.warning({ message: 'Pago', description: error.response.data, duration: 10 });
            } finally {
                setLoading(false);
            }

        });
    };

    const handleCancel = () => {
        form.resetFields();
        setBtnDis(false);
        props.onCancel();
    }

    const handleOnInput = (e) => {
        const total = form.getFieldValue('total').substring(1).toString().replace(',', '');
        const pago = e.target.value.toString().replace(',', '');
        if (parseFloat(pago) >= parseFloat(total)) {
            const camb = parseFloat(pago) - parseFloat(total);
            form.setFieldsValue({
                cambio: `$${parseFloat(camb).toFixed(4)}`
            });
        } else {
            form.setFieldsValue({ cambio: `$0` });
        }
    }

    return <Modal className='noImprimible' title="Registrar pago"
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okButtonProps={{ disabled: btnDis }}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cerrar">
        <Row>
            <Form form={form} layout="vertical" className='all-width'>
                <Form.Item name="total" label="Total">
                    <Input disabled={true} className='txt-money' />
                </Form.Item>
                <Form.Item name="pago" label="Pago Cliente" dependencies={['total']}
                    rules={[
                        { required: true, message: 'Falta colocar el monto' },
                        { pattern: MONTO_PATTERN, message: 'No es un monto aceptado' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const cant = getFieldValue('total').substring(1).toString().replace(',', '');
                                if (parseFloat(cant) > parseFloat(value.toString().replace(',', '')))
                                    return Promise.reject(new Error('El pago es menor al total.'));
                                return Promise.resolve();
                            }
                        })
                    ]}>
                    <Input className='txt-money' prefix={'$'} onChange={handleOnInput} />
                </Form.Item>
                <Form.Item name="cambio" label="Cambio" >
                    <Input disabled={true} className='txt-money' />
                </Form.Item>
            </Form>
        </Row>
    </Modal>
}

export default PaymentModal;