import React, { useState, useEffect } from 'react'
import { Modal, Input, Row, Form, notification } from 'antd'
import { MONTO_PATTERN } from "../../utils/regex";
import { bonusPayment } from '../../services/paymentService';
import config from '../../config.json';

const { TextArea } = Input;

function PaymentBonusModal(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!props.service) return;
        form.setFieldsValue(props.service);
    }, [props.service, form]);

    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            try {
                const request = {
                    clientId: props.service.clientId,
                    accountingId: props.service.accountingId,
                    accountingDetailId: props.service.accountingDetailId,
                    value: values.bono,
                    text: values.text,
                    salesOfficeId: props.salesOfficeId
                };
                await bonusPayment(request);
                form.setFieldsValue({ bono: 0 });
                props.onOk();
                notification.success({ message: 'Bonificación', description: 'Se aplicó la bonificación.', });
                form.resetFields();
            } finally {
                setLoading(false);
            }
        });
    };

    const handleCancel = () => {
        form.setFieldsValue({ bono: 0 });
        props.onCancel();
    }

    return <Modal title="Bonificación"
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Row>
            <Form form={form} layout="vertical" className='all-width'>
                <Form.Item name="contractId" label="No. servicio">
                    <Input disabled={true} className='txt-money' />
                </Form.Item>
                <Form.Item name="bono" label="Monto a bonificar"
                    rules={[
                        { required: true, message: 'Falta colocar el monto' },
                        { pattern: MONTO_PATTERN, message: 'No es un monto aceptado' },
                        () => ({
                            validator(_, value) {
                                if (parseFloat(value) > parseFloat(props.service.totalDebit))
                                    return Promise.reject(new Error(`El bono no debe ser mayor a la cantidad ${new Intl.NumberFormat(config.locale, { style: 'currency', currency: config.currency }).format(props.service.totalDebit)}.`));
                                return Promise.resolve();
                            }
                        })
                    ]}>
                    <Input className='txt-money' prefix={'$'} />
                </Form.Item>
                <Form.Item name="text" label="Texto de bonificación"
                    rules={[
                        { required: true, message: 'Falta colocar un texto' },
                    ]}>
                    <TextArea rows={4} maxLength={128} />
                </Form.Item>
            </Form>
        </Row>
    </Modal>
}

export default PaymentBonusModal;