import React, { useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Upload, notification } from 'antd';
import { upload } from '../../../../services/fileService'

const UploadItem = (props) => {
    const [file, setFile] = useState(props.img);

    const normFile = (e) => {
        return Array.isArray(e) ? e : e && e.fileList;
    };

    const apiAWS = async (file) => {
        try {
            const {data} = await upload(file);
            props.datason({'id': props.characteristicId, 'url': data.url}, false)
        } catch (error) {
            notification.error({ message: 'Archivo', description: 'Error al subir archivo.' });
            setFile(null);
        }
    };

    const prp = {
        name: 'file',
        listType: 'picture',
        beforeUpload: (file) => {
            setFile(file);
            apiAWS(file);
            return false;
        },
        onRemove(){
            setFile(null);
            props.datason({'id': props.characteristicId}, true);
        }
    };

    return <Form.Item name={props.name} label={props.label} rules={props.required}
        initialValue={file}
        valuePropName="fileList"
        getValueFromEvent={normFile}>
        <Upload {...prp} defaultFileList={file}>
            {!file && <Button icon={<UploadOutlined />}>Click to upload</Button>}
        </Upload>
    </Form.Item>
}

export default UploadItem;