import React from 'react';

import { AiFillHome } from 'react-icons/ai';
import { FaBook, FaWifi, FaTags, FaCubes } from 'react-icons/fa';
import { MdPayments, MdOutlineInventory2 } from 'react-icons/md';
import { TbReplace, TbReportMoney, TbReportAnalytics } from 'react-icons/tb';
import { RiArticleLine, RiBookletFill } from 'react-icons/ri';

export const SidebarData = [
	{
		title: 'Home',
		path: '/intelinet',
		icon: <AiFillHome />,
	},
	{
		title: 'Planes',
		path: '/intelinet/planes',
		icon: <RiBookletFill />,
		roles: 'PLAN_READ'
	},
	{
		title: 'Clientes',
		path: '/intelinet/clientes',
		icon: <FaBook />,
		roles: 'CLIENT_READ'
	},
	{
		title: 'Servicios',
		path: '/intelinet/servicios',
		icon: <FaWifi />,
		roles: 'SERVICE_READ'
	},
	{
		title: 'Pagos',
		path: '/intelinet/pagos',
		icon: <MdPayments />,
		roles: 'PAYMENT_READ'
	},
	{
		title: 'Reporte adeudos',
		path: '/intelinet/reporte-adeudos',
		icon: <TbReportAnalytics />,
	},
	{
		title: 'Reporte Movim.',
		path: '/intelinet/reporte-movimientos',
		icon: <TbReportMoney />,
	},
	{
		title: 'Reporte Corte',
		path: '/intelinet/reporte-corte',
		icon: <RiArticleLine />,
	},
	{
		title: 'Movimientos',
		path: '/intelinet/movimientos',
		icon: <TbReplace />,
	},
	{
		title: 'Inventario',
		path: '/intelinet/inventario',
		icon: <MdOutlineInventory2 />,
	},
	{
		title: 'Categorias',
		path: '/intelinet/categorias',
		icon: <FaTags />,
		roles: 'CATEGORY_READ'
	},
	{
		title: 'Productos',
		path: '/intelinet/productos',
		icon: <FaCubes />,
		roles: 'PRODUCT_READ'
	},

];
