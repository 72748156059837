import { Form, Button, Row, Col, Typography, Spin } from 'antd';
import React, { useState } from 'react';

import { cancelAccounting } from '../../services/accountService';

export default function Cancel({ accountingId, onOkCancelAccounting }) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { Title } = Typography;

	const handleFinish = async () => {
		try {
			setLoading(true);
			await cancelAccounting(accountingId);
			onOkCancelAccounting();
		} catch (error) {
			/*
			! Revisar response para settear mensaje de error 
			*/
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{!loading ? (
				<Form layout='vertical' form={form} onFinish={handleFinish}>
					<Row justify='center' align='middle'>
						<Col span={24}>
							<Title level={5}>
								¿Estas seguro en cancelar movimiento contable?
							</Title>
						</Col>
					</Row>
					<br />
					<Row justify='center' align='middle'>
						<Col span={12}>
							<Form.Item>
								<Button type='primary' htmlType='submit' size='large'>
									Sí
								</Button>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item>
								<Button
									type='danger'
									htmlType='submit'
									size='large'
									onClick={() => onOkCancelAccounting()}>
									No
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			) : (
				<Row justify='center' align='middle'>
					<Col span={24} style={{ textAlign: 'center' }}>
						<Spin size='large' />
						<Title level={3}>Procesando</Title>
					</Col>
				</Row>
			)}
		</>
	);
}
