import React, { useState } from 'react';
import { Form, Modal, notification } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx';

import config from '../../config.json';
import { createCustomer } from '../../services/customerService'
import CustomerForm from './CustomerForm';

function CustomerModal(props) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleOk = async (values) => {
        setLoading(true);
        
        let birthDate = null;
        if (values.birthDate)
            birthDate = moment(values.birthDate.startOf('day')).format();

        values.phone2 = values.phone2 === "" ? null : values.phone2;
        values.emailPrincipal = values.emailPrincipal === "" ? null : values.emailPrincipal;
        values.emailAlternative = values.emailAlternative === "" ? null : values.emailAlternative;
        
        try {
            const { data } = await createCustomer({ ...values, birthDate, clientGroupId: config.clientGroups.general });
            form.resetFields();
            props.onOk(data);
            notification.success({ message: 'Clientes', description: 'Se creó el cliente.' });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        props.onCancel();
        form.resetFields();
    }

    return <Modal
        title="Nuevo Cliente"
        open={props.visible}
        onCancel={handleCancel}
        footer={null}
        width={1000}>
            <CustomerForm form={form} btnLoading={loading} initialValues={{ rfc: 'XAXX010101000' }} onFinish={handleOk} />
    </Modal>;
}

export default CustomerModal;