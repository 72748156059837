import http from "./httpService";

const endPoint = "/ServicePlans";

export function create(request) {
    return http.post(endPoint, request);
}

export function getAll() {
    return http.get(endPoint);
}

export function update(id, request) {
    return http.put(`${endPoint}/${id}`, request);
}

export default {
    create,
    getAll,
    update
};