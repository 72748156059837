import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Table, Row, Col, notification, Tooltip, Tag } from 'antd';
import { getAdeudos } from '../../services/paymentService';
import moment from 'moment';
import { CSVLink } from "react-csv";

import config from '../../config.json';

function Debit() {
    const navigate = useNavigate();
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [btnExcelVisible, setBtnExcelVisible] = useState(false);
    const headers = [
        { label: "No. Documento", key: "accountingId" },
        { label: "Fecha documento", key: "creationDate" },
        { label: "Precio", key: "price" },
        { label: "Plan", key: "description" },
        { label: "No. servicio", key: "contractId" },
        { label: "Descuento", key: "discount" },
        { label: "Total", key: "total" },
        { label: "Referencia", key: "periodicity" }
    ];

    useEffect(() => {
        getDataTable();
    }, []);

    const getDataTable = async (e) => {
        setTableLoading(true);
        setBtnExcelVisible(false);
        try {
            const { data } = await getAdeudos();
            const newData = data.map((obj) => (
                {
                    ...obj,
                    id: `${obj.accountingId}${obj.accountingDetailId}`
                }
            ));
            if (data.length === 0) {
                notification.success({ message: 'Reporte', description: 'No se encontraron datos.', });

            } else {
                setBtnExcelVisible(true);
            }

            setDataTable(newData);
        } finally {
            setTableLoading(false);
        }
    }

    const columns = [
        { title: 'No. Documento', dataIndex: 'accountingId' },
        { title: 'Fecha documento', dataIndex: 'creationDate', render: (value) => moment(value).format(config.dateFormat) },
        { title: 'Precio', align: 'right', dataIndex: 'price', render: (value) => `$${parseFloat(value).toFixed(4)}` },
        { title: 'Plan', dataIndex: 'description' },
        {
            title: 'No. servicio', dataIndex: 'contractId', align: 'center',
            render: (value) => (
                <Tooltip title="Editar servicio">
                    <Tag className='hand' color="blue" onClick={() => navigate(`/intelinet/servicios/contrato/${value}`)}> &nbsp;{value}&nbsp; </Tag>
                </Tooltip>)
        },
        { title: 'Descuento', align: 'right', dataIndex: 'discount', render: (value) => `$${parseFloat(value).toFixed(4)}` },
        {
            title: 'Total', align: 'right', dataIndex: 'total', render: (value, record) => {
                if (record.debitCreditIndicator === 'C')
                    return `$${parseFloat(value * -1).toFixed(4)}`
                return `$${parseFloat(value).toFixed(4)}`
            }
        },
        { title: 'Referencia', dataIndex: 'periodicity' },
        { title: 'Doc. referencia', dataIndex: 'parentId' },
    ];

    return <div className='main-container'>
        <Row>
            <Col span={24}>
                <h1 className="title">Adeudos</h1>
                <hr className="hr-title" />
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}><br /></Col>
            <Col
                span={6}
                className={'collapse' + (btnExcelVisible ? 'in' : '')}>
                <CSVLink data={dataTable} headers={headers}
                    filename={"ReporteAdeudos.csv"}
                    className="ant-btn ant-btn-primary">Descargar reporte</CSVLink>
            </Col>
            <Col span={24}>
                <br />
                <Table rowKey="id" dataSource={dataTable} columns={columns} loading={tableLoading} />
            </Col>
        </Row>
    </div>
}

export default Debit;