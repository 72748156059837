import { Table, Button, Row, Col, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { IoIosList, IoMdDocument } from 'react-icons/io';
import { getActiveCategories } from '../../services/CategoriesService';
import ModalCategory from '../../components/modalCategory';
import MovementProductsModal from '../../components/modalProducts';
import { getAllPlants } from '../../services/PlantsService';
import { getProducts } from '../../services/ProductsService';
import { getStock } from '../../services/StockService';

import ModalInput from '../../components/modalInput';

export default function StockMaterials() {
	const { Option } = Select;
	const [dataTableCompleta, setDataTableCompleta] = useState([]);
	const [tableLoading, setTableLoading] = useState(false);
	const [storageLoading, setStorageLoading] = useState(false);
	const [dataProducts, setDataProducts] = useState([]);
	const [dataCategories, setDataCategories] = useState([]);
	const [dataPlantsStorage, setDataPlantsStorage] = useState([]);
	const [dataStorage, setDataStorage] = useState([]);
	const [modalVisibleProducts, setModalVisibleProducts] = useState(false);
	const [modalVisibleCat, setModalVisibleCat] = useState(false);
	const [modalVisibleLote, setModalVisibleLote] = useState(false);
	const [modalVisibleStock, setModalVisibleStock] = useState(false);
	const [filterCategory, setfilterCategory] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [selectedStorage, setSelectedStorage] = useState([]);
	const [selectedPlants, setSelectedPlants] = useState([]);
	const [selectedCat, setSelectedCat] = useState([]);
	const [selectedLote, setSelectedLote] = useState([]);
	const [selectedStock, setSelectedStock] = useState([]);
	const [btnExcelVisible, setBtnExcelVisible] = useState(false);
	const headers = [
		{ label: "ID", key: "product.id" },
		{ label: "Producto", key: "product.name" },
		{ label: "Categoría", key: "category.name" },
		{ label: "Stock", key: "stock" },
		{ label: "Unidad", key: "unitOfMeasure.unit" },
		{ label: "Stock transito", key: "stockInTransit" },
		{ label: "Stock seguridad", key: "safeStock" },
		{ label: "Costo", key: "product.cost" },
		{ label: "Valor stock", key: "valueStock" },
		{ label: "Valor stock transito", key: "valueStockInTransit" },
		{ label: "Almacén", key: "storageLocation.name" },
		{ label: "Planta", key: "plant.name" },
		{ label: "Usuario", key: "user.userName" }
	];

	useEffect(() => {
		async function plantsStorage() {
			setStorageLoading(true);
			const { data } = await getAllPlants();
			setDataPlantsStorage(data);
			setDataStorage(data[0].storageLocations);
			setStorageLoading(false);
		}
		async function categorias() {
			const { data } = await getActiveCategories();
			const filter = [];
			data.map(function (obj) {
				const item = {
					text: obj.name,
					value: obj.name,
				};
				filter.push(item);
				return true;
			});
			setDataCategories(data);
			setfilterCategory(filter);
		}

		async function products() {
			const { data } = await getProducts();
			setDataProducts(data);
		}
		plantsStorage();
		products();
		categorias();
	}, []);

	function handleChangePlant(value) {
		setSelectedPlants(value);
	}

	function handleChangeStorage(value) {
		setSelectedStorage(value);
	}

	const handleCancelModalProducts = () => {
		setModalVisibleProducts(false);
	};

	const handleOkModalProducts = data => {
		setSelectedProducts(data);
		setModalVisibleProducts(false);
	};

	const handleClickModalMovProd = () => {
		setModalVisibleProducts(true);
	};

	const handleCancelModalCat = () => {
		setModalVisibleCat(false);
	};

	const handleOkModalCat = data => {
		setSelectedCat(data);
		setModalVisibleCat(false);
	};

	const handleClickModalCat = () => {
		setModalVisibleCat(true);
	};

	const handleCancelModalLote = () => {
		setModalVisibleLote(false);
	};

	const handleOkModalLote = data => {
		setSelectedLote(data);
		setModalVisibleLote(false);
	};

	const handleClickModalLote = () => {
		setModalVisibleLote(true);
	};

	const handleCancelModalStock = () => {
		setModalVisibleStock(false);
	};

	const handleOkModalStock = data => {
		setSelectedStock(data);
		setModalVisibleStock(false);
	};

	const handleClickModalStock = () => {
		setModalVisibleStock(true);
	};

	const handleClickReport = async () => {
		setDataTableCompleta([]);
		setBtnExcelVisible(false);
		const request = {
			Plants: selectedPlants,
			Products: selectedProducts,
			StorageLocations: selectedStorage,
			CategoriesRange: selectedCat,
			stockTypes: selectedStock,
			batchs: selectedLote
		};
		setTableLoading(true);
		async function getReport() {
			const { data } = await getStock(request);
			if (data.length === 0) {
				notification.info({ message: 'Reporte', description: 'No se encontraron datos con los filtros seleccionados.', });
			} else {
				setBtnExcelVisible(true);
			}

			setDataTableCompleta(data);
			setTableLoading(false);
		}
		getReport();
	};

	const rowClassColor = (record, index) =>
		record.valueStock >= 0 ? 'rowGreen' : 'rowRed';

	const columnsComplete = [
		{
			title: 'ID',
			dataIndex: 'product',
			width: 100,
			fixed: 'left',
			render: value => value.id,
		},
		{
			title: 'Producto',
			dataIndex: 'product',
			render: value => value.name,
		},
		{
			title: 'Categoría',
			dataIndex: 'category',
			render: value => value.name,
		},
		{
			title: 'Stock',
			dataIndex: 'stock',
			render: value => value,
		},
		{
			title: 'Unidad',
			dataIndex: 'unitOfMeasure',
			render: value => value.unit,
		},
		{
			title: 'Stock transito',
			dataIndex: 'stockInTransit',
			render: value => value,
		},
		{
			title: 'Stock seguridad',
			dataIndex: 'safeStock',
			render: value => value,
		},
		{
			title: 'Tipo stock',
			dataIndex: 'stockTypeId',
			render: value => value === "C" ? "Comodato" : "Libre Utilización",
		},
		{
			title: 'costo',
			dataIndex: 'product',
			render: value => value.cost,
		},
		{
			title: 'Valor stock',
			dataIndex: 'valueStock',
			render: value => value,
		},
		{
			title: 'Valor stock transito',
			dataIndex: 'valueStockInTransit',
			render: value => value,
		},
		{
			title: 'Lote',
			dataIndex: 'batch',
			render: value => value,
		},
		{
			title: 'Almacén',
			dataIndex: 'storageLocation',
			render: value => value.name,
		},
		{
			title: 'Planta',
			dataIndex: 'plant',
			render: value => value.name,
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: value => value.userName,
		},
	];

	return (
		<div className='main-container'>
			<MovementProductsModal
				modalVisibleProducts={modalVisibleProducts}
				handleCancelModalProducts={handleCancelModalProducts}
				handleOkModalProducts={handleOkModalProducts}
				dataProducts={dataProducts}
				filterCategory={filterCategory}
			/>

			<ModalCategory
				modalVisibleCat={modalVisibleCat}
				handleCancelModalCat={handleCancelModalCat}
				handleOkModalCat={handleOkModalCat}
				dataCategories={dataCategories}
			/>

			<ModalInput
				visible={modalVisibleLote}
				title="Lote"
				handleCancelModalCat={handleCancelModalLote}
				handleOkModalCat={handleOkModalLote}
			/>

			<ModalInput
				visible={modalVisibleStock}
				title="Tipo Stock"
				handleCancelModalCat={handleCancelModalStock}
				handleOkModalCat={handleOkModalStock}
				dataValidate={["C", "I"]}
			/>

			<Row>
				<Col span={24}>
					<h1 className='title'>Reporte de inventario</h1>
					<hr className='hr-title' />
				</Col>
			</Row>

			<Row className='detail-container'>
				<Col span={20}></Col>
				<Col span={4}>
					<Button
						block
						type='primary'
						icon={<IoMdDocument />}
						onClick={handleClickReport}>
						&nbsp; Generar
					</Button>
				</Col>
				<Col span={8} className="mrg-2">
					<Button
						block
						type='primary'
						icon={<IoIosList />}
						onClick={handleClickModalMovProd}>
						&nbsp; Productos...
					</Button>
				</Col>
				<Col span={2}></Col>
				<Col span={8} className="mrg-2">
					<Button
						block
						type='primary'
						icon={<IoIosList />}
						onClick={handleClickModalCat}>
						&nbsp; Categorias...
					</Button>
				</Col>
				<Col span={6}></Col>
				<Col span={8} className="mrg-2">
					<Button
						block
						type='primary'
						icon={<IoIosList />}
						onClick={handleClickModalLote}>
						&nbsp; Lotes...
					</Button>
				</Col>
				<Col span={2}></Col>
				<Col span={8} className="mrg-2">
					<Button
						block
						type='primary'
						icon={<IoIosList />}
						onClick={handleClickModalStock}>
						&nbsp; Tipo Stock...
					</Button>
				</Col>
				<Col span={6}></Col>
				<Col span={8} className="mrg-2">
					<Select
						mode='multiple'
						loading={storageLoading}
						style={{ width: '100%' }}
						onChange={handleChangePlant}
						placeholder='Plantas...'
						maxTagCount='responsive'>
						{dataPlantsStorage.map(function (obj) {
							return (
								<Option key={obj.id} value={obj.id}>
									{obj.name}
								</Option>
							);
						})}
					</Select>
				</Col>
				<Col span={2}></Col>
				<Col span={8} className="mrg-2">
					<Select
						mode='multiple'
						loading={storageLoading}
						style={{ width: '100%' }}
						onChange={handleChangeStorage}
						placeholder='Almacenes...'
						maxTagCount='responsive'>
						{dataStorage.map(function (obj) {
							return (
								<Option key={obj.id} value={obj.id}>
									{obj.name}
								</Option>
							);
						})}
					</Select>
				</Col>
				<Col span={6}></Col>

				<Col span={24} className="mrg-2">
					<Row>
						<Col
							span={6}
							className={'collapse' + (btnExcelVisible ? 'in' : '')}>
							<CSVLink data={dataTableCompleta} headers={headers}
								filename={"ReporteInventario.csv"}
								className="ant-btn ant-btn-primary">Descargar reporte</CSVLink>
						</Col>
						<Col span={24}>
							<Table
								rowKey='key'
								dataSource={dataTableCompleta}
								columns={columnsComplete}
								loading={tableLoading}
								scroll={{ x: 'auto' }}
								rowClassName={rowClassColor}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
