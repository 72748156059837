import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, notification, Row, Col } from 'antd';
import { create, update } from '../../services/planService';
import { NUMBERS_POSITIVES_PATTERN, MONTO_PATTERN } from '../../utils/regex';
import moment from 'moment';


function PlanModal(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (!props.plan) return;

        setIsDisabled(true);
        form.setFieldsValue({ ...props.plan, id: props.plan.productId });
    }, [props.plan, form]);




    const handleOk = () => {
        form.validateFields().then(async values => {
            setLoading(true);
            let validFrom = moment(moment().startOf('day')).format();
            let validTo = moment('9999-12-31').format();

            if (props.plan) {
                validFrom = props.plan.validFrom;
                validTo = props.plan.validTo;
            }

            const request = {
                'id': values.id,
                'name': values.name,
                'price': values.price,
                'discountAmount': values.discountAmount,
                'discountDays': values.discountDays,
                'periodicity': 'M',
                'cutDays': [
                    1
                ],
                validFrom,
                validTo
            };

            if (props.plan) {
                try {
                    const { data } = await update(props.plan.productId, { ...request, salesOrganizationId: props.plan.salesOrganizationId, categoryId: props.plan.categoryId });
                    notification.success({ message: 'Planes', description: 'Se actualizó el plan.' });
                    props.onOk(data);
                    form.resetFields();
                    setIsDisabled(false);
                } catch (error) {
                    if (error.response && error.response.status === 400)
                        notification.warning({ message: 'Planes', description: error.response.data.errors[""][0], duration: 10 });
                } finally {
                    setLoading(false);
                }
            } else {
                try {
                    const { data } = await create(request);
                    form.resetFields();
                    props.onOk(data);
                    notification.success({ message: 'Planes', description: 'Se creó el plan.' });
                    setIsDisabled(false);
                } catch (error) {
                    if (error.response && error.response.status === 400)
                        notification.warning({ message: 'Planes', description: error.response.data.errors[""][0], duration: 10 });
                } finally {
                    setLoading(false);
                }
            }
        }).catch(() => { });
    };

    const handleCancel = () => {
        setIsDisabled(false);
        form.resetFields();
        props.onCancel();
    }

    const handleInputTrim = ({ target }) => form.setFieldValue(target.id, target.value.trim());

    const idsValidator = (value) => {
        if (props.plan) return false;
        const item = props.dataPlans.find(
            element => element.productId.toUpperCase() === value.toUpperCase()
        );
        if (item) return true;
        return false;
    };

    return <Modal
        title={`${props.plan ? "Editar" : "Nuevo"} Plan`}
        open={props.visible}
        confirmLoading={loading}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar">
        <Form form={form} layout="vertical" className='all-width'>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="id" label="ID"
                        rules={[{ pattern: NUMBERS_POSITIVES_PATTERN, message: 'ID no valido', required: true },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (idsValidator(value))
                                    return Promise.reject(new Error("El ID ya existe"));
                                return Promise.resolve();
                            }
                        })]}>
                        <Input onBlur={handleInputTrim} disabled={isDisabled} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="name" label="Nombre"
                        rules={[{ required: true, message: 'El Nombre no debe ser vacío' }]}>
                        <Input onBlur={handleInputTrim} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="price" label="Precio"
                        rules={[{ required: true, message: 'El Precio no debe ser vacío' },
                        { pattern: MONTO_PATTERN, message: 'No es un monto aceptado' }]}>
                        <Input onBlur={handleInputTrim} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="discountDays" label="Días descuento" initialValue={0}
                        rules={[{ pattern: NUMBERS_POSITIVES_PATTERN, message: 'Cantidad de días no valido' }]}>
                        <Input type='number' onBlur={handleInputTrim} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="discountAmount" label="Monto descuento" initialValue={0}
                        rules={[{ pattern: MONTO_PATTERN, message: 'No es un monto aceptado' }]}>
                        <Input onBlur={handleInputTrim} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>;
}

export default PlanModal;