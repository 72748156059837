import { Button, Form, Select, Input, Row, Col, notification } from 'antd';
import {
	PlusOutlined,
	MinusCircleOutlined,
	CheckCircleTwoTone,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { IP_PATTERN } from '../../utils/regex';

import { getCategoriesProducts } from '../../services/CategoriesService';
import { createServiceOrder } from '../../services/serviceOrderService';
import { getWorkCenters } from '../../services/workCenterService';
import auth from "../../services/authService";

export default function Equipment({
	contractId,
	clientId,
	salesOfficeId,
	setReloadContract,
	productId,
	planCategoryId,
	onActivateContract,
}) {
	const categoryId = 2;
	const onlyActive = true;
	const customerId = '0073f19e-6666-4907-b93b-eaaaa2c2e885';
	const organizationId = 'Intelinet';
	const [products, setProducts] = useState([]);
	const [workCenters, setWorkCenters] = useState([]);
	const [allWorkCenters, setAllWorkCenters] = useState([]);
	const [radioBases, setRadioBases] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingForm, setLoadingForm] = useState(false);
	const [form] = Form.useForm();
	const stockTypes = [
		{
			label: 'Venta',
			value: 'I',
		},
		{
			label: 'Comodato',
			value: 'C',
		},
	];

	const loadCatalogs = async () => {
		try {
			setLoading(true);
			const [productsResult, workCentersResult] = await Promise.all([
				getCategoriesProducts(
					categoryId,
					onlyActive,
					customerId,
					organizationId
				),
				getWorkCenters(),
			]);

			const products = productsResult.data.products
				.map(product => ({
					value: product.id,
					label: product.id + ' | ' + product.name,
				}))
				.sort((a, b) => a.label.localeCompare(b.label));
			const workCenters = workCentersResult.data;
			const allRadioBasesIds = workCentersResult.data.map(
				workCenter => workCenter.workCenterLocationId
			);
			const radioBasesIds = [...new Set(allRadioBasesIds)];
			const radioBases = radioBasesIds
				.map(radioBaseId => {
					const workCenter = workCenters.find(
						workCenter => workCenter.workCenterLocationId === radioBaseId
					);

					return {
						value: radioBaseId,
						label: workCenter.workCenterLocationName,
					};
				})
				.sort((a, b) => {
					return a.label.localeCompare(b.label);
				});
			setRadioBases(radioBases);
			setAllWorkCenters(workCenters);
			setProducts(products);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadCatalogs();
		form.resetFields();
	}, [contractId]);

	const handleFilter = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleFinish = async values => {
		try {
			setLoadingForm(true);
			const components = values.equipments.map(product => ({
				plantId: '1',
				storageLocationId: '1',
				categoryId,
				productId: product.productId,
				batch: product.lote,
				quantity: product.quantity,
				unitOfMeasureId: 'UN',
				stockType: product.stockTypeId,
			}));
			const contract = {
				contractId,
				categoryId: planCategoryId,
				productId,
				clientId,
				workCenterId: values.workCenterId,
				salesOfficeId,
				includeInstallService: true,
				components,
				reference: values.ip,
			};

			await createServiceOrder(contract, customerId, organizationId);

			notification.success({
				message: 'Sistema',
				description: 'Se ha activado el contrato',
			});

			setReloadContract(true);
			onActivateContract();
		} catch (error) {
			if (error.response && error.response.status === 400)
				notification.warning({ message: 'Activar', description: error.response.data, duration: 7 });
		} finally {
			setLoadingForm(false);
		}
	};

	const handleRadioBaseChange = radioBaseId => {
		form.setFieldValue('workCenterId', null);
		const workCentersRadioBase = allWorkCenters.filter(
			workCenter => workCenter.workCenterLocationId === radioBaseId
		);

		const workCenters = workCentersRadioBase
			.map(x => ({
				value: x.id,
				label: x.name,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		setWorkCenters(workCenters);
	};

	return (
		<>
			<Form form={form} onFinish={handleFinish}>
				<Row>
					<Col span={24}>
						<Form.Item
							name='radioBaseId'
							label='Radio base'
							rules={[
								{
									required: true,
									message: '¡Selecciona una radio base!',
								},
							]}>
							<Select
								showSearch={true}
								style={{ width: '100%' }}
								placeholder='Radio base'
								loading={loading}
								options={radioBases}
								filterOption={handleFilter}
								onChange={handleRadioBaseChange}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name='workCenterId'
							label='Sector'
							rules={[
								{
									required: true,
									message: '¡Selecciona un sector!',
								},
							]}>
							<Select
								showSearch={true}
								style={{ width: '100%' }}
								placeholder='Sector'
								loading={loading}
								options={workCenters}
								filterOption={handleFilter}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name='ip'
							label='IP'
							rules={[{
								required: true,
								maxLength: 15,
								message: '¡Inserta una IP!',
								pattern: IP_PATTERN
							}]}>
							<Input placeholder='IP' />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.List name='equipments'>
							{(fields, { add, remove }) => (
								<>
									{fields.map(field => (
										<Row key={field.key} gutter={5}>
											<Col span={6}>
												<Form.Item
													{...field}
													name={[field.name, 'productId']}
													label='Equipo'
													rules={[
														{
															required: true,
															message: '¡Selecciona un equipo!',
														},
													]}>
													<Select
														showSearch={true}
														style={{ width: '100%' }}
														placeholder='Asus AX6000'
														loading={loading}
														options={products}
														filterOption={handleFilter}
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													{...field}
													name={[field.name, 'lote']}
													label='Lote'>
													<Input maxLength={17} />
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item
													{...field}
													name={[field.name, 'quantity']}
													label='Cantidad'
													rules={[
														{
															required: true,
															message: '¡Ingresa una cantidad!',
														},
													]}
													initialValue={1}>
													<Input
														placeholder='¡Ingresa un número!'
														type='number'
														min={1}
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													{...field}
													name={[field.name, 'stockTypeId']}
													label='Tipo de entrega'
													rules={[
														{
															required: true,
															message: '¡Selecciona un tipo de entrega!',
														},
													]}>
													<Select
														showSearch={true}
														style={{ width: '100%' }}
														placeholder='Entrega'
														loading={loading}
														options={stockTypes}
														filterOption={handleFilter}
													/>
												</Form.Item>
											</Col>
											<Col span={1}>
												<MinusCircleOutlined
													onClick={() => remove(field.name)}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button
											type='dashed'
											onClick={() => add()}
											block
											icon={<PlusOutlined />}>
											Añadir equipo
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</Col>
				</Row>
				<Row>
					<Col span={4} offset={20}>
						<Form.Item>
							<Button
								type='primary'
								htmlType='submit'
								size='large'
								loading={loadingForm}
								icon={<CheckCircleTwoTone twoToneColor={'#00924c'} />}
								disabled={!auth.isInRoles('SERVICE_ACTIVATE')}>
								Activar
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);
}
