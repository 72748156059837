import React from 'react';

export default function TemplateRoom({ title, disabled, onClick }) {
	return (
		<div className='app-template-room-container'>
			<div
				className={`app-template-room${
					disabled ? ' app-template-room-disabled' : ''
				}`}
				onClick={onClick}>
				{title}
			</div>
		</div>
	);
}
