import http from './httpService';

export async function addCategory(request) {
	return await http.post('/Categories', request);
}

export async function getAllCategories() {
	return await http.get('/Categories/All');
}

export async function getActiveCategories() {
	return await http.get(`/Categories`);
}

export function updateCategory(id, request) {
	return http.put(`/Categories/${id}`, request);
}

export function updateCategoryImage(id, request) {
	return http.put(`/Categories/${id}/Image`, request);
}

export function getCategoriesProducts(id, onlyActive) {
	return http.get(`/Categories/${id}/Products`, {
		params: {
			id,
			onlyActive,
		},
	});
}

export async function addToStorageLocation(category, product, request) {
	return await http.post(
		`/Categories/${category}/Product/${product}/StorageLocations`,
		request
	);
}

export async function getProductStorageLocations(category, product) {
	return await http.get(
		`/Categories/${category}/Product/${product}/StorageLocations`,
		{
			params: {
				categoryId: category,
				productId: product,
			},
		}
	);
}

export default {
	addCategory,
	getAllCategories,
	getActiveCategories,
	updateCategory,
	updateCategoryImage,
	getCategoriesProducts,
	addToStorageLocation,
	getProductStorageLocations,
};
