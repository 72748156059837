import http from './httpService';

export async function addSet(request) {
	return await http.post(`/Set`, request);
}

export async function updateSet(id, request) {
	return await http.put(`/Set/${id}`, request);
}

export async function getSet(setId) {
	return await http.get(`/Set`, {
		params: {
			id: setId,
		},
	});
}

export function getAllSet() {
	return http.get(`/Set/All`);
}

export default { addSet, updateSet, getAllSet, getSet };
