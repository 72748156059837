import { useMutation, useQueryClient } from '@tanstack/react-query';

import { suspendContract } from './../services/contractService';

const useAddSuspension = ({ onError, onSettled, onSuccess }) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newSuspension) => suspendContract(newSuspension).then(res => res.data),
        onSuccess: (newSuspension) => {
            queryClient.setQueryData(
                ['suspensions', newSuspension.contractId],
                (suspensions) => [
                    ...suspensions,
                    { ...newSuspension, user: { 
                        userId: newSuspension.user.Id,
                        userName: newSuspension.user.name
                    }}
                ]);
            onSuccess();
        },
        onError,
        onSettled
    });
}

export default useAddSuspension;