import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Skeleton } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';

import config from "../../config.json"
import { EMAIL_PATTERN, RFC_PATTERN, TEN_DIGITS_PATTERN } from '../../utils/regex';
import useCountries from './../../hooks/useCountries';
import { getPostalCodes } from './../../services/postalCodeService';
import { normalized } from './../../utils/string';


const { Option } = Select;
const { TextArea } = Input;

const rules = {
    email: { pattern: EMAIL_PATTERN, message: 'Nombre de usuario no es una dirección de correo' },
    phone: { pattern: TEN_DIGITS_PATTERN, message: 'El Teléfono debe contener 10 digitos' }
};

const CustomerForm = ({ btnLoading, form, initialValues, onFinish }) => {
    const [showRazonSocial, setShowRazonSocial] = useState(initialValues && initialValues.razonSocial);
    const [cities, setCities] = useState([]);
    const [loadingColonies, setLoadingColonies] = useState(false);
    const [colonies, setColonies] = useState([]);
    const { isLoading, data: countries } = useCountries();

    const getCities = (country, state) => countries
        .find(c => c.id === country).states
        .find(s => s.id === state).municipalities
        .sort((a, b) => a.name.localeCompare(b.name));

    const getStates = () => countries
        .find(c => c.id === config.country).states
        .sort((a, b) => a.name.localeCompare(b.name));
    
    useEffect(() => {
        if(!initialValues?.address || !countries) return;
        
        const stateId = initialValues.address.countryStateID;
        const cityId = initialValues.address.countryStateMunicipalityID;

        const citiesList = getCities(config.country, stateId);
        setCities(citiesList);

        fillColonies(config.country, stateId, cityId);
    }, [countries]);

    if(isLoading) return <Skeleton active />

    const handleChangeCity = async (value) => {
        form.setFieldValue(["address", "postalCodeID"], null);

        await fillColonies(
            config.country,
            form.getFieldValue(["address", "countryStateID"]),
            value
        );
    }

    const handleChangeState = (value) => {
        form.setFieldValue(["address", "countryStateMunicipalityID"], null);
        form.setFieldValue(["address", "postalCodeID"], null);
        const items = getCities(config.country, value);
        setCities(items);
    }

    const handleInputRFC = ({ target: { value }}) => setShowRazonSocial(value.length === 12);

    const handleInputTrim = ({ target }) => form.setFieldValue(target.id, target.value.trim());

    const handleFinish = (values) => onFinish({
        ...values,
        address: { ...values.address, countryID: config.country }
    });
    
    const filterOption = (input, option) => normalized(option.children).includes(normalized(input));

    const fillColonies = async (country, state, city) => {
        setLoadingColonies(true);

        try {
            const { data } = await getPostalCodes(country, state, city);
            const items = data.map(pc => ({ value: pc.id, label: `${pc.code} ${pc.settlement}`}));
            setColonies(items);
        } finally {
            setLoadingColonies(false);
        }
    }

    return <Form form={form} initialValues={initialValues} layout="vertical" className='all-width' onFinish={handleFinish}>
        <Row gutter={8}>
            <Col span={8}>
                <Form.Item name="name" label="Nombre(s)"
                    rules={[{ required: true, message: 'El Nombre no debe ser vacío' }]}>
                    <Input onBlur={handleInputTrim} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name="lastName" label="Apellido paterno"
                    rules={[{ required: true, message: 'El Apellido paterno no debe ser vacío' }]}>
                    <Input onBlur={handleInputTrim} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name="motherLastName" label="Apellido materno"
                    rules={[{ required: true, message: 'El Apellido materno no debe ser vacío' }]}>
                    <Input onBlur={handleInputTrim} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span={8}>
                <Form.Item name="birthDate" label="Fecha de nacimiento">
                    <DatePicker locale={locale} format={config.dateFormat} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name="phone1" label="Teléfono principal"
                    rules={[{ ...rules.phone, required: true }]}>
                    <Input maxLength={10} placeholder="4317461234" />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name="phone2" label="Alternativo"
                    rules={[rules.phone]}>
                    <Input maxLength={10} placeholder="4317461234" />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span={8}>
                <Form.Item name="gender" label="Género"
                    rules={[{ required: true, message: 'Selecciona el género' }]}>
                    <Select allowClear>
                        <Option value="M">Masculino</Option>
                        <Option value="F">Femenino</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name="emailPrincipal" label="Email principal" rules={[rules.email]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name="emailAlternative" label="Alternativo" rules={[rules.email]}>
                    <Input />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span={8}>
                <Form.Item name="rfc" label="RFC"
                    rules={[{ pattern: RFC_PATTERN, message: 'RFC no valido', required: true }]}>
                    <Input onInput={handleInputRFC} />
                </Form.Item>
            </Col>
            {showRazonSocial && <Col span={8}>
                <Form.Item name="razonSocial" label="Razón social" rules={[{ required: true, message: 'Coloca la Razón social' }]}>
                    <Input />
                </Form.Item>
            </Col>}
        </Row>
        <Divider orientation='left'>Domicilio</Divider>
        <Row gutter={8}>
            <Col span={8}>
                <Form.Item name={["address", "countryStateID"]} label="Estado"
                    rules={[{ required: true, message: 'Selecciona el Estado' }]}>
                    <Select onChange={handleChangeState} showSearch filterOption={filterOption}>
                        {getStates().map(({ id, name}) => <Option key={id} value={id}>{name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={["address", "countryStateMunicipalityID"]} label="Municipio"
                    rules={[{ required: true, message: 'Selecciona el Municipio' }]}>
                    <Select onChange={handleChangeCity} showSearch filterOption={filterOption}>
                        {cities.map((c) => <Option key={c.id} value={c.id}>{c.name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={["address", "postalCodeID"]} label="Colonia">
                    <Select loading={loadingColonies}
                        allowClear
                        showSearch
                        filterOption={filterOption} >
                            {colonies.map(({ value, label}) => <Option key={value} value={value}>{label}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span={16}>
                <Form.Item name={["address", "street"]} label="Calle"
                    rules={[{ required: true, message: 'Coloca la calle' }]}>
                    <TextArea rows={1} maxLength={150} />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item name={["address", "numberExternal"]} label="N° Externo"
                    rules={[{ required: true, message: 'Coloca el N° Externo' }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item name={["address", "numberInternal"]} label="N° Interno">
                    <Input />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span={12}>
                <Form.Item name={["address", "reference"]} label="Referencia"
                    rules={[{ required: true, message: 'Coloca una Referencia' }]}>
                    <TextArea rows={1} maxLength={128}/>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={["address", "location"]} label="Localidad" >
                    <TextArea rows={1} maxLength={128} />
                </Form.Item>
            </Col>
        </Row>
        <Form.Item>
            <Button type="primary" htmlType="submit" loading={btnLoading}>Guardar</Button>
        </Form.Item>
    </Form>
}

export default CustomerForm