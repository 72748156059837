import { Radio, Row, Col, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { getStats } from '../../services/contractService';

export default function StyledRadio({
	color,
	text,
	status,
	reload,
	statuses,
	setStatuses,
}) {
	const [loading, setLoading] = useState(false);
	const [stats, setStats] = useState(0);

	async function loadStats() {
		try {
			setLoading(true);
			const { data } = await getStats(status);
			setStats(data);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		loadStats();
	}, []);

	useEffect(() => {
		if (reload) {
			loadStats();
			const statusIndex = statuses.findIndex(index => index.status === status);
			statuses[statusIndex].reload = false;

			setStatuses(statuses);
		}
	}, [reload]);

	return (
		<Radio.Button
			value={status}
			size='large'
			style={{
				height: '100px',
				borderRadius: '0.5rem',
				width: '200px',
				margin: '1rem',
			}}>
			<Row justify='center' align='middle'>
				<Col
					span={2}
					style={{
						background: color,
						height: '100px',
						width: '30px',
						borderRadius: '0.5rem',
					}}></Col>
				<Col span={18} offset={2}>
					<div style={{ textAlign: 'center' }}>
						{text}
						<br />
						{loading ? <Spin /> : <span>{stats}</span>}
					</div>
				</Col>
			</Row>
		</Radio.Button>
	);
}
