import './index.css';

import logo from '../../assets/images/Intelinet.png';

import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import React, { useEffect } from 'react';

import useAuth from '../../hooks/useAuth';
import packageInfo from '../../../package.json';

export default function Login() {
	const { Text, Title } = Typography;
	const { login, loginLoading, loginError, isUserAuthenticated } = useAuth();

	const handleLogin = async ({ username, password }) => {
		await login({ username, password });
	};

	useEffect(() => {
		if (isUserAuthenticated) window.location = '/intelinet';
	}, [isUserAuthenticated]);

	return (
		<Row className='login-row'>
			<Col xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 12 }}>
				<div className='login-circle'>
					<div className='login-container'>
						<img className='logo' alt='logo' src={logo} />
						<Row align='center'>
							<Col>
								<Title level={5} style={{ justifyContent: 'center' }}>
									V {packageInfo.version}
								</Title>
							</Col>
						</Row>
						<Form className='login-form' onFinish={handleLogin}>
							<Form.Item
								name='username'
								rules={[{ required: true, message: 'Escribe tu usuario!' }]}>
								<Input
									prefix={<UserOutlined className='site-form-item-icon' />}
									placeholder='Usuario'
								/>
							</Form.Item>
							<Form.Item
								name='password'
								rules={[{ required: true, message: 'Escribe tu contraseña!' }]}>
								<Input.Password
									prefix={<LockOutlined className='site-form-item-icon' />}
									placeholder='Contraseña'
								/>
							</Form.Item>
							{loginError && (
								<Form.Item>
									<Text type='danger' style={{ margin: '1rem' }}>
										{loginError.message}
									</Text>
								</Form.Item>
							)}
							<Form.Item>
								<Button
									className='btn-submit btn-login'
									loading={loginLoading}
									htmlType='submit'
									size='large'
									block>
									Entrar
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</Col>
		</Row>
	);
}
