import http from './httpService';

export function addTemplate(template) {
	return http.post('/Template', template);
}

export function getAllTemplates() {
	return http.get('/Template');
}

export function getTemplate(id) {
	return http.get(`/Template/${id}`);
}

export function updateTemplate(id, request) {
	return http.put(`/Template/${id}`, request);
}

export function deleteTemplate(id) {
	return http.delete(`/Template/${id}`);
}

export function applyTemplate(id) {
	return http.post(`/Template/${id}/Apply`);
}

export default {
	addTemplate,
	getAllTemplates,
	getTemplate,
	updateTemplate,
	deleteTemplate,
	applyTemplate,
};
