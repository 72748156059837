import React, { useState, useEffect } from "react";
import { Button, Row, Col, Table, Input, Tooltip } from 'antd';
import { FaPlusCircle, FaPencilAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { getCustomers } from '../../services/customerService';
import { normalized } from "../../utils/string";
import auth from "../../services/authService";

import CustomerModal from "./CustomerModal";

function Customers() {
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [tempDataTable, setTempDataTable] = useState([]);


    useEffect(() => {
        getDataTable();
    }, []);

    const getDataTable = async () => {
        setTableLoading(true);
        try {
            const { data } = await getCustomers();

            const fdorder = data.sort((a, b) => {
                const nameA = fullName(a).toUpperCase();
                const nameB = fullName(b).toUpperCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            });

            setDataTable(fdorder);
            setTempDataTable(fdorder);
        } finally {
            setTableLoading(false);
        }
    }

    const handleOk = (item) => {
        const tempData = [...dataTable];
        tempData.unshift(item);
        setDataTable(tempData);
        setTempDataTable(tempData);
        setModalVisible(false);
    }

    const handleSearch = (e) => {
        const currValue = e.target.value;
        setSearchValue(currValue);
        if (!currValue)
            return setDataTable(tempDataTable);

        const filteredData = tempDataTable.filter(entry =>
            normalized(fullName(entry)).includes(normalized(currValue)) ||
            normalized(entry.id.toString()).includes(normalized(currValue)) ||
            (entry.razonSocial && normalized(entry.razonSocial).includes(normalized(currValue))) ||
            (entry.address && normalized(fullAddress(entry)).includes(normalized(currValue)))
        );
        setDataTable(filteredData);
    };

    const fullName = (item) => `${item.name} ${item.lastName} ${item.motherLastName}`;

    const fullAddress = (item) => item.address ? item.address.numberInternal ? `${item.address.street} ${item.address.numberExternal} - ${item.address.numberInternal}` : `${item.address.street} ${item.address.numberExternal}` : item.address;

    const columns = [
        { title: 'ID', dataIndex: 'id' },
        { title: 'Nombre', render: (_, record) => fullName(record) },
        { title: 'Razón social', dataIndex: 'razonSocial' },
        { title: 'Domicilio', render: (_, record) => fullAddress(record) },
        {
            title: '', align: 'center',
            render: (_, record) => (
                <Tooltip title="Editar">
                    <Link className="ant-btn ant-btn-circle ant-btn-primary ant-btn-icon-only" to={`/intelinet/clientes/${record.id}`}>{<FaPencilAlt />}</Link>
                </Tooltip>)
        },
    ];

    return <div className="main-container">
        <CustomerModal visible={modalVisible} onOk={handleOk} onCancel={() => setModalVisible(false)}/>
        <Row>
            <Col span={24}>
                <h1 className="title">Clientes</h1>
                <hr className="hr-title" />
            </Col>
        </Row>
        <Row className="detail-container">
            <Col span={24}>
                <div className="div-right">
                    <Button type="primary" icon={<FaPlusCircle />} shape="round" onClick={() => setModalVisible(true)}
                        disabled={!auth.isInRoles('CLIENT_CREATE')}>
                        &nbsp; Nuevo
                    </Button>
                </div>
            </Col>
            <Col span={6}>
                <br />
                <Input
                    placeholder="Buscar"
                    value={searchValue}
                    onChange={handleSearch} />
            </Col>
            <Col span={24}>
                <br />
                <Table rowKey="id" dataSource={dataTable} columns={columns} loading={tableLoading} />
            </Col>
        </Row>
    </div>
}

export default Customers;