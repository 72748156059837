import {
    Table,
    Button,
    Row,
    Col,
    notification,
    Typography,
    Switch,
    Select
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { IoMdDocument, IoMdCalendar } from 'react-icons/io';
import { getAccMov } from '../../services/accountingMovementService';
import { getSalesOfficesMy } from '../../services/salesOfficeService';
import config from '../../config.json';
import ModalFecha from '../../components/modalFecha';

const { dateTimeFormat } = config;
const { Text } = Typography;
const { Option } = Select;

export default function SatReport() {
    const [dataTable, setDataTable] = useState([]);
    const [dataTable2, setDataTable2] = useState([]);
    const [dataSalesOffice, setDataSalesOffice] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalVisibleFecha, setModalVisibleFecha] = useState(false);
    const [selectedFechas, setSelectedFechas] = useState([]);
    const [btnExcelVisible, setBtnExcelVisible] = useState(false);
    const [rptDetalle, setRptDetalle] = useState(false);
    const [selLoading, setSelLoading] = useState(false);
    const [selVisible, setSelVisible] = useState(false);
    const [selectedSalesOffice, setSelectedSalesOficce] = useState([]);
    const headers = [
        { label: '# Ticket ', key: 'ticketNumber' },
        { label: 'Importe', key: 'value' },
        { label: 'Fecha', key: 'date' },
        { label: 'Cliente', key: 'clientName' },
        { label: 'Plan', key: 'plan' },
        { label: 'Texto', key: 'text' },
        { label: 'Oficina', key: 'oficina' },
    ];
    const headers2 = [
        { label: '# Ticket ', key: 'ticketNumber' },
        { label: 'Importe', key: 'value' },
        { label: 'Fecha', key: 'date' },
        { label: 'Cliente', key: 'clientName' },
    ];

    useEffect(() => {
        async function salesOffice() {
            setSelLoading(true);
            const { data } = await getSalesOfficesMy();
            setDataSalesOffice(data);
            setSelLoading(false);
            if (data.length > 1)
                return setSelVisible(true);
            handleChangeSalesOffice([data[0].id])

        }
        salesOffice();
    }, []);

    function handleChangeSalesOffice(value) {
        setSelectedSalesOficce(value);
    }

    const handleClickModalFecha = () => {
        setModalVisibleFecha(true);
    };

    const handleCancelModalFecha = () => {
        setModalVisibleFecha(false);
    };

    const handleOkModalFecha = data => {
        const items = [];
        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            const f = moment(element.low, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss');
            let f2 = null;

            if (element.high)
                f2 = moment(element.high, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss');

            items.push({
                key: items.length + 1,
                option: element.option,
                sign: element.sign,
                low: f,
                high: f2,
            });

        }
        setSelectedFechas(items);
        setModalVisibleFecha(false);
    };

    const handleClickReport = async () => {
        if (selectedSalesOffice.length === 0)
            return notification.info({ message: 'Reporte', description: selVisible ? 'Selecciona oficina(s) de ventas.' : 'No tienes una oficina de ventas asignada.' });
        setDataTable([]);
        setDataTable2([]);
        setBtnExcelVisible(false);
        const request = {
            ClientPostings: true,
            DateRange: selectedFechas,
            salesOffices: selectedSalesOffice
        };
        setTableLoading(true);
        async function getReport() {
            const { data } = await getAccMov(request);
            if (data.length === 0) {
                notification.info({ message: 'Reporte', description: 'No se encontraron datos con los filtros seleccionados.' });
                setTableLoading(false);
                return false;
            } else {
                setBtnExcelVisible(true);
            }

            const dataModel = [];
            const dataModel2 = [];
            let indx = 1;
            for (let x = 0; x < data.length; x++) {
                const header = data[x];
                if (header.accountingDetails.length > 0) {
                    for (let y = 0; y < data[x].accountingDetails.length; y++) {
                        const detail = data[x].accountingDetails[y];
                        if (header.ticketNumber !== null && detail.accountingTypeId === 'PC' && detail.debitCreditIndicator === 'D') {

                            dataModel.push({
                                key: indx,
                                ticketNumber: header.ticketNumber,
                                date: header.date,
                                clientName: `${header.client.name} ${header.client.lastName} ${header.client.motherLastName}`,
                                value: header.cancellationId !== null ? 0 : detail.value,
                            });
                            dataModel2.push({
                                key: indx,
                                ticketNumber: header.ticketNumber,
                                date: header.date,
                                clientName: `${header.client.name} ${header.client.lastName} ${header.client.motherLastName}`,
                                plan: detail.product.name,
                                value: header.cancellationId !== null ? 0 : detail.value,
                                planId: detail.product.id,
                                text: detail.text,
                                oficina: header.salesOffice.name
                            });
                            indx++;
                        }
                    }
                }
            }
            fillTableSimple(dataModel);
            fillTableDetalle(dataModel2);
            setTableLoading(false);
        }
        getReport();
    };

    const fillTableDetalle = (data) => {
        const result = data.reduce(function (res, value) {
            const idx = res[0].indexOf(value.ticketNumber + '-' + value.planId);
            if (idx > -1) {
                res[1][idx].value += value.value
                if (value.text)
                    res[1][idx].text ? res[1][idx].text += ' / ' + value.text : res[1][idx].text = value.text;

            } else {
                res[0].push(value.ticketNumber + '-' + value.planId);
                res[1].push(value);
            }
            return res;
        }, [[], []]);
        const dataOrder = result[1].sort(function (a, b) {
            return a.ticketNumber - b.ticketNumber;
        });
        setDataTable(dataOrder);
    }

    const fillTableSimple = (data) => {
        const result2 = data.reduce(function (res, value) {
            const idx = res[0].indexOf(value.ticketNumber);
            if (idx > -1) {
                res[1][idx].value += value.value
            } else {
                res[0].push(value.ticketNumber);
                res[1].push(value);
            }
            return res;
        }, [[], []]);
        const dataOrder2 = result2[1].sort(function (a, b) {
            return a.ticketNumber - b.ticketNumber;
        });
        setDataTable2(dataOrder2);
    }

    const formatNumber = number => {
        const imp = ((number * 100) / 100).toFixed(2);
        return imp;
    };

    const columns = [
        {
            title: '# Ticket',
            dataIndex: 'ticketNumber',
        },
        {
            title: 'Importe',
            dataIndex: 'value',
            width: 180,
            render: value => `$ ${formatNumber(value)}`,
        },
        {
            title: 'Fecha',
            dataIndex: 'date',
            width: 180,
            render: date => moment(date).format(dateTimeFormat),
        },
        {
            title: 'Cliente',
            dataIndex: 'clientName',
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            render: value => value,
        },
        {
            title: 'Texto',
            dataIndex: 'text',
            render: value => value,
        },
        {
            title: 'Oficina',
            dataIndex: 'oficina',
            render: value => value,
        },

    ];

    const columns2 = [
        {
            title: '# Ticket',
            dataIndex: 'ticketNumber',
        },
        {
            title: 'Importe',
            dataIndex: 'value',
            width: 180,
            render: value => `$ ${formatNumber(value)}`,
        },
        {
            title: 'Fecha',
            dataIndex: 'date',
            width: 180,
            render: date => moment(date).format(dateTimeFormat),
        },
        {
            title: 'Cliente',
            dataIndex: 'clientName',
        }

    ];

    return (
        <>
            <div className='noImprimible'>
                <ModalFecha
                    modalVisibleFecha={modalVisibleFecha}
                    handleCancelModalFecha={handleCancelModalFecha}
                    handleOkModalFecha={handleOkModalFecha}
                />
                <Row>
                    <Col span={24}>
                        <h1 className='title'>Reporte Corte</h1>
                        <hr className='hr-title' />
                    </Col>
                </Row>
                <Row className='detail-container'>
                    <Col span={20}></Col>
                    <Col span={4}>
                        <div className=''>
                            <Button
                                block
                                type='primary'
                                icon={<IoMdDocument />}
                                onClick={handleClickReport}>
                                &nbsp; Generar
                            </Button>
                        </div>
                    </Col>
                    <Col span={8} className='mrg-2'>
                        <Select
                            className={'mrg-2 collapse' + (selVisible ? 'in' : '')}
                            mode='multiple'
                            loading={selLoading}
                            style={{ width: '100%' }}
                            onChange={handleChangeSalesOffice}
                            defaultValue={Object.keys(dataSalesOffice)[0]}
                            placeholder='Oficina de ventas...'
                            maxTagCount='responsive'>
                            {dataSalesOffice.map(function (obj) {
                                return (
                                    <Option key={obj.id} value={obj.id}>
                                        {obj.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col span={16}></Col>
                    <Col span={8} className='mrg-2'>
                        <div className=''>
                            <Button
                                block
                                type='primary'
                                icon={<IoMdCalendar />}
                                onClick={handleClickModalFecha}>
                                &nbsp; Fechas...
                            </Button>
                        </div>
                    </Col>

                    <Col span={24} className='mrg-2'>
                        <Row>
                            <Col span={24}>
                                <Switch checkedChildren="Corte" unCheckedChildren="SAT" onChange={(checked) => setRptDetalle(checked)} />
                            </Col>
                        </Row>
                        <Row className='mrg-2'>
                            <Col
                                span={6}
                                className={'collapse' + (btnExcelVisible ? 'in' : '')}>
                                <CSVLink
                                    data={rptDetalle ? dataTable : dataTable2}
                                    headers={rptDetalle ? headers : headers2}
                                    filename={'Reporte-Corte.csv'}
                                    className='ant-btn ant-btn-primary'>
                                    Descargar reporte
                                </CSVLink>
                            </Col>
                            <Col span={24} className={'collapse' + (rptDetalle ? 'in' : '')}>
                                <Table
                                    className='mrg-2'
                                    rowKey='key'
                                    dataSource={dataTable}
                                    columns={columns}
                                    loading={tableLoading}
                                    scroll={{ x: 'auto' }}
                                    pagination={{ showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100, 200, 300, 500, 1000] }}
                                    summary={pageData => {
                                        let total = 0;

                                        pageData.forEach(({ value }) => {
                                            total += value;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell colSpan={5}>
                                                        <Text type="primary">{`$ ${formatNumber(total)}`}</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                />
                            </Col>
                            <Col span={24} className={'collapse' + (rptDetalle ? '' : 'in')}>
                                <Table
                                    className='mrg-2'
                                    rowKey='key'
                                    dataSource={dataTable2}
                                    columns={columns2}
                                    loading={tableLoading}
                                    scroll={{ x: 'auto' }}
                                    pagination={{ showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100, 200, 300, 500, 1000] }}
                                    summary={pageData => {
                                        let total = 0;

                                        pageData.forEach(({ value }) => {
                                            total += value;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell colSpan={5}>
                                                        <Text type="primary">{`$ ${formatNumber(total)}`}</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}
