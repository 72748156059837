import React, { useState } from 'react';
import { notification, Button, Form } from 'antd';
import UploadItem from './characteristics/items/UploadItem';
import { addResults } from "./../../services/characteristicsService";

const CharacteristicsForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [saveDisable, setSaveDisable] = useState(true);
    const [dataValues, setDataValues] = useState(props.datos);
    const [form] = Form.useForm();

    // This will remove properties and will be stored in formProps variable
    const { objectId, submitBtnWrapperCol, items, datos, ...formProps } = props;

    const handleFinish = async () => {
        form.validateFields().then(async values => {
            setLoading(true);

            const results = dataValues.map((d) => ({
                characteristicId: d.characteristicId,
                classCategoryId: d.classCategoryId,
                classId: d.classId,
                classTypeId: d.classTypeId,
                stringValue: d.stringValue
            }));

            try {
                await addResults(results, objectId);
                notification.success({ message: 'Clientes', description: 'Se actualizó adjuntos clientes.' });
            } catch (error) {
                if (error.response && error.response.status === 400)
                    notification.warning({ message: 'Clientes', description: error.response.data });
            }finally {
                setLoading(false);
            }
        }).catch(() => { });
    }

    const datason = (values, accion) => {
        setSaveDisable(true);
        const tempData = [...dataValues];
        const item = tempData.find(item => item.characteristicId === values.id);
        if (accion) {
            item.stringValue = null;
        } else {
            item.stringValue = values.url;
        }
        setDataValues(tempData);
        if(!item.mandatory || (item.mandatory && item.stringValue !== null))
        setSaveDisable(false);
    }

    return <Form form={form} {...formProps} onFinish={handleFinish}>
        {props.items.map(i => {
            switch(i.characteristicType.id){
                case "297ccbac-5431-4629-8dee-87e3c13693d2":{
                    const info =  datos.filter(d => d.characteristicId === i.characteristicId)[0];
                    let dat = null;
                    if(info.stringValue)
                        dat = [{name: info.name, url: `${window.location.origin}${info.stringValue}`}];
                    return <UploadItem key={i.characteristicId} datason={datason} name={i.name} label={i.name} required={[{required: info.mandatory, message: 'El campo es requerido'}]} characteristicId={i.characteristicId} img={dat}/>;
                }
                default:
                    return null;
            }
        })}
        <Form.Item wrapperCol={submitBtnWrapperCol}>
            <Button type="primary" htmlType="submit" loading={loading} disabled={saveDisable}>Guardar</Button>
        </Form.Item>
    </Form>
}

export default CharacteristicsForm;