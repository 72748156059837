// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import React, { createContext, useState } from 'react';

const Context = createContext({});

export function UserContextProvider({ children }) {
	const [token, setToken] = useState(() =>
		window.localStorage.getItem('token')
	);
	const [customerId, setCustomerId] = useState(
		'0073f19e-6666-4907-b93b-eaaaa2c2e885'
	);
	const [organizationId, setOrganizationId] = useState('Intelinet');
	const [user, setUser] = useState(() => {
		if (token) {
			const decoded = jwt_decode(token);
			return decoded;
		}
		return null;
	});
	const [userRoles, setUserRoles] = useState(() => {
		if (user) {
			if (user.role.constructor !== Array) {
				return user.role.split(',');
			}

			return user.role;
		}

		return null;
	});

	return (
		<Context.Provider
			value={{
				token,
				setToken,
				customerId,
				setCustomerId,
				organizationId,
				setOrganizationId,
				user,
				setUser,
				userRoles,
				setUserRoles,
			}}>
			{children}
		</Context.Provider>
	);
}

export default Context;
