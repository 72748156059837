import http from "./httpService";

const endPoint = "/AccountingMovement";

export function createPayment(id, request, office) {
    return http.post(`${endPoint}/PostMultiple?clientId=${id}&salesOfficeId=${office}`, request);
}

export function advancePayment(contractId, office) {
    return http.post(`${endPoint}/${contractId}?salesOfficeId=${office}`);
}

export function bonusPayment(request) {
    return http.post(`${endPoint}/BonusPayment`, request);
}

export function getMovements(id) {
    return http.get(`${endPoint}?clientId=${id}`);
}

export function getAdeudos(id) {
    return http.get(`${endPoint}?due=true`);
}

export function getInfoTicket(id) {
    return http.get(`${endPoint}/GetDetailById/${id}`);
}

export default {
    createPayment,
    getMovements,
    advancePayment,
    bonusPayment,
    getInfoTicket,
    getAdeudos
};