import { useQuery } from "@tanstack/react-query";

import { getContractStatusTypeByStatus } from "../services/contractStatusTypeService";

const useContractStatusTypes = (statusId) =>  useQuery({
    queryKey: ['contract-status-types', statusId],
    queryFn: () => getContractStatusTypeByStatus(statusId).then(res => res.data),
    staleTime: 30 * 24 * 60 * 60 * 100  // 1 month
});

export default useContractStatusTypes;