import logo from '../../assets/images/Intelinet.png';

import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa';
import { ImExit } from 'react-icons/im';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SidebarData } from './sidebarData';
import SubMenu from './subMenu';
import useAuth from '../../hooks/useAuth';

const Nav = styled.div`
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
	position: sticky;
	top: 0;
	border-radius: 0px 0px 15px 15px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

const NavIcon = styled(Link)`
	margin-left: 2rem;
	font-size: 2rem;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const NavIcon2 = styled.div`
	margin-right: 2rem;
	font-size: 1rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
`;

const SidebarNav = styled.nav`
	width: 260px;
	height: 100vh;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
	transition: 350ms;
	z-index: 10;
`;

const SidebarWrap = styled.div`
	width: 100%;
`;

const SidebarWrapContent = styled.div`
	width: 100%;
	overflow-y: auto;
`;

const ContentDiv = styled.div`
	display: flex;
`;

const SidebarLink = styled.div`
	display: flex;
	color: #e1e9fc;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	list-style: none;
	height: 60px;
	text-decoration: none;
	font-size: 18px;

	&:hover {
		background: #545450;
		border-left: 4px solid #ffffff;
		cursor: pointer;
		color: #e1e9fc;
	}

	&:active {
		color: #e1e9fc;
	}
`;

const SidebarLabel = styled.span`
	margin-left: 16px;
`;

export default function Sidebar() {
	const { logout, isUserAuthenticated, userRoles } = useAuth();
	const [sidebar, setSidebar] = useState(false);

	const handleLogOut = () => {
		logout();
		window.location = '/intelinet/login';
	};

	const showSidebar = () => setSidebar(!sidebar);

	return (
		<IconContext.Provider value={{ color: '#fff' }}>
			<Nav className='noImprimible menu-color' style={{ zIndex: 99 }}>
				<NavIcon to='#'>
					<FaBars className='icon-barr' onClick={showSidebar} />
				</NavIcon>
				<ContentDiv>
					<NavIcon2>
						<img
							style={{
								height: '45%',
								position: 'absolute',
								zIndex: '1',
								marginRight: '6rem',
								borderRadius: '7px',
							}}
							className='layout-logo'
							alt='logo'
							src={logo}
						/>
					</NavIcon2>
				</ContentDiv>
			</Nav>
			<SidebarNav className='noImprimible menu-color' sidebar={sidebar}>
				<SidebarWrap>
					<NavIcon to='#'>
						<AiOutlineClose onClick={showSidebar} />
					</NavIcon>
					<SidebarWrapContent className='scroll-nav'>
						{isUserAuthenticated &&
							SidebarData.reduce(
								(p, c, i) =>
									(
										c.roles
											? userRoles.some(role => c.roles.includes(role))
											: true
									)
										? [
											...p,
											<SubMenu item={c} key={i} showSidebar={showSidebar} />,
										]
										: p,
								[]
							)}
						{isUserAuthenticated && (
							<SidebarLink onClick={handleLogOut}>
								<div>
									<ImExit />
									<SidebarLabel>Salir</SidebarLabel>
								</div>
							</SidebarLink>
						)}
					</SidebarWrapContent>
				</SidebarWrap>
			</SidebarNav>
		</IconContext.Provider>
	);
}
