import logo from '../../assets/images/Intelinetsvg.svg';

import React, { useEffect, useState } from 'react';
import { getInfoTicket } from '../../services/paymentService';
import moment from 'moment';

export default function Ticket(props) {
	const [data, setData] = useState([]);
	const [subtotal, setSubtotal] = useState(0);
	const [bonifica, setBonifica] = useState(0);
	const [detail, setDetail] = useState([]);
	const [service, setService] = useState("");
	const [base64, setBase64] = useState(null);

	useEffect(() => {
		function toDataUrl(url, callback) {
			const xhr = new XMLHttpRequest();
			xhr.onload = function () {
				const reader = new FileReader();
				reader.onloadend = function () {
					callback(reader.result);
				}
				reader.readAsDataURL(xhr.response);
			};
			xhr.open('GET', url);
			xhr.responseType = 'blob';
			xhr.send();
		}
		toDataUrl(logo, function (myBase64) {
			setBase64(myBase64);
		});

	}, []);

	useEffect(() => {
		if (!props.accountingId) return;
		async function dtable() {
			const { data } = await getInfoTicket(props.accountingId);
			setData(data);
			const credit = data.detail.filter(d => d.debitCreditIndicator === 'C');
			const bon = credit.reduce(
				(accumulator, currentValue) => accumulator + currentValue.value,
				0
			);
			setBonifica(bon);
			const debit = data.detail.filter(d => d.debitCreditIndicator === 'D');
			const sub = debit.reduce(
				(accumulator, currentValue) => accumulator + currentValue.value,
				0
			);
			setSubtotal(sub);
			const sort = data.detail.sort((a, b) => {
				const nameA = a.debitCreditIndicator.toUpperCase(); // ignore upper and lowercase
				const nameB = b.debitCreditIndicator.toUpperCase(); // ignore upper and lowercase
				if (nameB < nameA)
					return -1;
				if (nameB > nameA)
					return 1;
				// names must be equal
				return 0;
			});
			setDetail(sort);

			const serv = data.detail.reduce((set, e) =>
				e.contractId ? set.add(parseInt(e.contractId)) : set,
				new Set());

			setService([...serv].toString());
		}
		dtable();
	}, [props.accountingId]);

	const numFormat = (numero) => {
		if (numero === null) return;
		const num = numero * 1;
		if (Number.isInteger(num))
			return num;
		const numArr = num.toString().split(".");
		const dec = numArr[1].substring(0, 2);
		return `${numArr[0]}.${dec}`;
	}

	return (
		<div>
			<div className="centrado">
				<img style={{ width: '100%' }} alt='logo' src={base64} />
			</div>
			<div className='t-info'>
				<p>{data.organizationName}</p>
				<p>Lugar Expedición: {data.salesOfficePostalCode}</p>
				<p>RFC: {data.organizationRFC}</p>
				<div id="contenedor">
					<div>
						<p>Recibo: # {(data.ticketNumber) * 1}</p>
					</div>
					<div>
						<p>Oficina: # {data.salesOfficeId}</p>
					</div>
				</div>
				<p>Fecha: {moment().format('DD/MM/YYYY HH:mm:ss ')}</p>
				<div id="contenedor">
					<div>
						<p>Cliente: {data.clientId}</p>
					</div>
					<div>
						<p>Servicio: # {service}</p>
					</div>
				</div>
				<p>{data.clientName}</p>
				<p>RFC: {data.clientRFC}</p>
				<p>&nbsp;</p>
			</div>
			<table>
				<thead>
				</thead>
				<tbody>
					{detail.map((element, index) => {
						if (element.debitCreditIndicator !== 'D')
							return false;
						return (
							<tr key={index}>
								<td className='producto'>{element.productDescription}&nbsp;{element.text}</td>
								<td className='precio txt-end'>
									${numFormat(element.value)}
								</td>
							</tr>
						);

					})}
					<tr>
						<td className='producto'>Bonificaciones:</td>
						<td className='precio txt-end'>
							-${numFormat(bonifica)}
						</td>
					</tr>
					<tr>
						<td><hr /></td>
						<td><hr /></td>
					</tr>
					<tr>
						<td className='producto'>Subtotal:</td>
						<td className='precio txt-end'>
							${numFormat(subtotal)}
						</td>
					</tr>
					<tr>
						<td className='producto'>Total:</td>
						<td className='precio txt-end'>
							${numFormat(subtotal - bonifica)}
						</td>
					</tr>
					<tr>
						<td className='producto'>Efectivo:</td>
						<td className='precio txt-end'>
							${numFormat(props.efectivo)}
						</td>
					</tr>
					<tr>
						<td className='producto'>Cambio:</td>
						<td className='precio txt-end'>
							${numFormat(props.cambio)}
						</td>
					</tr>
				</tbody>
			</table>
			<div className='foo-ticket'>
				<p>.</p>
			</div>
		</div>
	);
}
