import http from './httpService';

export async function addProduct(request) {
	return await http.post('/Products', request);
}

export async function getAllProducts(category) {
	return await http.get(`/Products/All`, {
		params: {
			categoryId: category,
		},
	});
}

export function getProducts(category) {
	return http.get('/Products', { params: { categoryId: category } });
}

export function updateProduct(id, request) {
	return http.put(`/Products/${id}`, request);
}

export function updateProductImage(id, request) {
	return http.put(`/Products/${id}/Image`, request);
}

export default {
	addProduct,
	getAllProducts,
	getProducts,
	updateProduct,
	updateProductImage,
};
