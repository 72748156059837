import './index.css';

import logo from '../../assets/images/Intelinet.png';

import { Row, Col } from 'antd';
import React from 'react';

export default function Home() {
	return (
		<Row justify='center' className='home-row'>
			<Col xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 12 }}>
				<div className='home-shadow'>
					<div className='home-container'>
						<img className='home-logo' alt='logo' src={logo} />

						<h1>¡Bienvenido!</h1>
					</div>
				</div>
			</Col>
		</Row>
	);
}
