import { Table, Button, Row, Col, Switch, Tooltip, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaPlusCircle, FaPencilAlt } from 'react-icons/fa';
import { getAllCategories, updateCategory } from '../../services/CategoriesService';
import CategoryModal from './CategoryModal';

export default function Category() {
	const [dataCategories, setDataCategories] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [category, setCategory] = useState(null);
	const [tableCategoryLoading, setTableCategoryLoading] = useState(false);

	useEffect(() => {
		setTableCategoryLoading(true);
		async function process() {
			const { data } = await getAllCategories();
			setDataCategories(data);
			setTableCategoryLoading(false);
		}
		process();
	}, []);

	const onChangeActive = (record, checked) => {
		const requestCategory = {
			id: record.id,
			name: record.name,
			active: checked,
		};
		async function updateTheCategory() {
			try {
				await updateCategory(record.id, requestCategory);
				const dataCat = [...dataCategories];
				dataCat.find(x => x.id === record.id).active = checked;
				setDataCategories(dataCat);
				notification.success({ message: 'Categoría', description: 'Se actualizó la categoría.' });

			} catch (error) {
				notification.error({ message: 'Categoría', description: 'Error al actualizar la categoría.' });

			}
		}
		updateTheCategory();
	};

	const handleClickModalNew = () => {
		setCategory(null);
		setModalVisible(true);
	};

	const handleClickModalEdit = record => {
		setCategory(record);
		setModalVisible(true);
	};

	const handleOk = (item) => {
		const tempData = [...dataCategories];
		if (category) {
			const indx = tempData.findIndex(n => n.id === item.id);
			tempData[indx] = { ...tempData[indx], ...item };
		} else {
			tempData.unshift(item);
		}

		setDataCategories(tempData);
		setModalVisible(false);
	}

	const handleModalCancel = () => {
		setCategory(null);
		setModalVisible(false);
	}

	const columns = [
		{
			title: 'Información',
			render: record => (
				<>
					{record.id}
					<br />
					{record.name}
				</>
			),
			responsive: ['xs'],
		},
		{
			title: 'ID',
			dataIndex: 'id',
			responsive: ['sm'],
		},
		{
			title: 'Nombre',
			dataIndex: 'name',
			responsive: ['sm'],
		},
		{
			title: '',
			dataIndex: 'active',
			align: 'center',
			render: (value, record) => (
				<Switch
					checked={value}
					onChange={checked => onChangeActive(record, checked)}
				/>
			),
		},
		{
			title: '',
			align: 'center',
			render: (value, record) => (
				<Tooltip title='Editar'>
					<Button
						type='primary'
						shape='circle'
						onClick={() => handleClickModalEdit(record)}
						icon={<FaPencilAlt />}
					/>
				</Tooltip>
			),
		},
	];

	return (
		<div className='mainContainer'>
			<CategoryModal visible={modalVisible}
				category={category} onOk={handleOk}
				onCancel={handleModalCancel}
			/>

			<Row>
				<Col span={24}>
					<h1 className='title'>Categorías</h1>
					<hr className='hr-title' />
				</Col>
			</Row>

			<Row className='detailContainer'>
				<Col span={24}>
					<div className='divRight'>
						<Button
							type='primary'
							icon={<FaPlusCircle />}
							onClick={handleClickModalNew}>
							&nbsp; Nueva
						</Button>
					</div>
				</Col>
				<Col span={24}>
					<br />
					<Table
						rowKey='id'
						dataSource={dataCategories}
						columns={columns}
						loading={tableCategoryLoading}
					/>
				</Col>
			</Row>
		</div>
	);
}
