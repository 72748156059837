import { useQuery } from "@tanstack/react-query";

import { getCharacteristics } from "../services/clientService";

const useClientCharacteristics = (clientGroup) => useQuery({
    queryKey: ['clients', 'characteristics', clientGroup],
    queryFn: () => getCharacteristics(clientGroup).then(res => res.data),
    staleTime: 365 * 24 * 60 * 60 * 1000  // 1 year
})

export default useClientCharacteristics;