export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[!@#$.%&*])(?=.*[a-zA-Z]).{8,}$/;

export const TEN_DIGITS_PATTERN = /^\d{10}$/;

export const RFC_PATTERN = /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/;

export const MONTO_PATTERN = /^[0-9]\d*(.\d+)?$/;

export const IP_PATTERN = /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const MAC_PATTERN = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;

export const LETTERS_NUMBERS_PATTERN = /^[a-zA-Z0-9]+$/;

export const NUMBERS_POSITIVES_PATTERN = /^[0-9]*$/;
